import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col, Spinner } from "react-bootstrap";
import Dashboardheader from "../../directives/dashboardheader";
import { GetOtpAction, LdpUpdateAction } from "../../Action/user.action";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import {
  approveTokenForLDP,
  getBalanceBlockchain,
  getPayableAmountBlockchain,
  joinLdpBlockchain,
} from "./LDPBlockchain";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";

const LDP1Qualifier = () => {
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  const loginData1 = !localStorage.getItem("loginSuccessStepMrMint")
    ? []
    : JSON.parse(localStorage.getItem("loginSuccessStepMrMint"));
  const [mobile, setMobile] = useState(loginData1.mobile || "");
  const [address, setAddress] = useState(loginData1.address || "");
  const [paymentOption, setPaymentOption] = useState(
    loginData1.paymentOption || "USDT"
  );
  const [getQrData, setQrData] = useState({});
  const [connectWalletAddress, setConnectWalletAddress] = useState("");
  const [currentBNBBalance, setcurrentBNBBalance] = useState("0.000000");
  const [payableAmount, setPayableAmount] = useState(0);
  const [currentTokenBalance, setCurrentTokenBalance] = useState({
    MNT: 0,
    USDT: 0,
  });
  const [currentLdpPlan, setCurrentLdpPlan] = useState(1);
  const [loader, setLoader] = useState(false);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [ldpStatus, setLdpStatus] = useState(0);
  const navigate = useNavigate();

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    Swal.fire({
      title: "Alert!",
      text: "Kindly refresh the page and clear your cache to proceed further.",
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    });
  };

  useEffect(() => {
    getQrCodeData();
    setTimeout(() => {
      if (loginData.id) {
        getBNBBalance();
      }
    }, 500);
  }, []);


  const getBNBBalance = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const res = await getBalanceBlockchain(accounts[0]);
      if (res.status) {
        setConnectWalletAddress(accounts[0]);
        setCurrentTokenBalance(res);
      }
    }
  };

  useEffect(() => {
    if (paymentOption && connectWalletAddress) {
      getPayableAmount();
    }
  }, [paymentOption, connectWalletAddress]);

  const getPayableAmount = async () => {
    const res = await getPayableAmountBlockchain(
      paymentOption,
      currentLdpPlan,
      connectWalletAddress
    );
    if (res.status) {
      setPayableAmount(res.amount);
    }
  };

  const getQrCodeData = async () => {
    setLoader(true);
    try {
      let res = await GetOtpAction();
      if (res.success) {
        if (res.data.ldpId) {
          setQrData(res.data);
          setLoader(false);
        } else {
          setLdpStatus(1);
          setLoader(false);

          // toast.error("Sorry You are not qualifing in LDP program")
          // setTimeout(()=>{
          // navigate(`${config.baseUrl}dashboard`)
          // },300)
        }
      } else {
        setQrData([]);
        setLoader(false);
      }
    } catch (error) {
      console.error("Error fetching QR code data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handleClick = async (isAttending) => {
    if (isAttending) {
      if (!mobile || !address) {
        toast.error("Please fill in all the details.");
        return;
      }
    }

    let text = "";
    if (getQrData.qualifyCount >= 1) {
      text = isAttending
        ? `Reserve Your Seat Today!. & Confirmed by just paying $100 Worth ${paymentOption} token.`
        : `Are you sure you do not want to attend the LDP program?`;
    } else {
      text = isAttending
        ? `Reserve Your Seat Today!.`
        : `By clicking "Disagree," your participation in this program will be canceled. Are you sure?`;
    }

    const result = await Swal.fire({
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });

    if (result.isConfirmed) {
      const obj = {
        user_id: loginData.id,
        id: getQrData.ldpId,
        isAttending,
        mobile,
        address,
        qualifyCount: getQrData.qualifyCount,
        updated: true,
      };

      if (getQrData.qualifyCount >= 1 && isAttending === 1) {
        const payRes = await joinLdp();
        if (payRes) {
          // Block user interaction for 1 minute
          await Swal.fire({
            title: "Please wait...",
            text: "Processing your request. Please wait for 1 minute.",
            icon: "info",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showConfirmButton: false,
            timer: 60000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          await LdpUpdateAction(obj);

          Swal.fire({
            title: "Success!",
            text: "Your payment was processed successfully.",
            icon: "success",
          }).then(() => {
            setTimeout(() => {
              window.location.reload();
            }, 300);
          });
        }
        //  else{
        //     toast.error(res.message)
        //  }
      } else {
        const resp = await LdpUpdateAction(obj);
        if (resp.isCacheClear) {
          clearCacheData();
        } else {
          toast.success("Your response is saved.");
          setTimeout(() => {
            window.location.reload();
          }, 300);
        }
      }
    }
  };

  const joinLdp = async () => {
    setPaymentLoader(true);

    // Show Swal alert while payment is processing
    const swalWait = Swal.fire({
      title: "Please wait...",
      text: "Your payment is being processed. This may take a few moments.",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    if (currentTokenBalance[paymentOption] < payableAmount) {
      toast.error(`Insufficient ${paymentOption} balance in your wallet`);
      setPaymentLoader(false);
      Swal.close();
      return;
    }

    let allowance =
      paymentOption === "MNT"
        ? currentTokenBalance["mntAllowance"]
        : currentTokenBalance["usdtAllowance"];
    if (allowance < payableAmount) {
      const approveRes = await approveTokenForLDP(
        paymentOption,
        currentLdpPlan,
        connectWalletAddress
      );
      if (!approveRes.status) {
        toast.error(approveRes.message);
        setPaymentLoader(false);
        Swal.close();
        return;
      }
    }

    const res = await joinLdpBlockchain(
      paymentOption,
      currentLdpPlan,
      connectWalletAddress,
      getQrData.ldpId
    );
    if (!res.status) {
      toast.error(res.message);
      setPaymentLoader(false);
      Swal.close();
      return;
    }

    if (res.status) {
      setPaymentLoader(false);
      Swal.close();
      toast.success("Transaction successful!");
      return true;
    }

    setPaymentLoader(false);
    Swal.close();
  };

  return (

    <>

      <Dashboardheader />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" alt="Shape" />
        <img src="assets/images/shape1.svg" className="shape1" alt="Shape1" />
        <img src="assets/images/shape2.svg" className="shape2" alt="Shape2" />
      </div>

      {loader ? (
        <div className="text-center d-flex align-items-center justify-content-center" style={{height:"80vh"}}>
          <Spinner animation="border" variant="primary" />
        </div>
      ) : ldpStatus ? (
        <div
          className="text-center d-flex justify-content-center align-items-center flex-column"
          style={{
            padding: "20px",
            color: "#d3d3d3",
            borderRadius: "8px",
            height: "80vh",
          }}
        >
        <img style={{width:"auto", height:"auto"}} src='assets/images/nodata2.png' />
          <h4>You are not qualified for LDP program</h4>
        </div>
      ) : getQrData.isCheckout === true ? (
        <div
          className="text-center d-flex justify-content-center align-items-center flex-column "
          style={{
            padding: "20px",
            color: "#d3d3d3",
            borderRadius: "8px",
            height: "80vh",
          }}
        >
         <img style={{width:"auto", height:"auto"}} src='assets/images/nodata2.png' />
          <h4>No data found</h4>
        </div>
      ) : (getQrData.isCheckout === false || getQrData.isCheckout === null) &&
        getQrData.isAttending === 0 &&
        getQrData.qrCode === null ? (
        <div
          className="text-center d-flex justify-content-center align-items-center flex-column"
          style={{
            padding: "20px",
            color: "#d3d3d3",
            borderRadius: "8px",
            height: "80vh",
          }}
        >
         <img style={{width:"auto", height:"auto"}} src='assets/images/nodata2.png' />
          <h4>No event found</h4>
        </div>
      ) : Object.keys(getQrData).length &&
        getQrData.qrCode != null &&
        !getQrData.isAttending &&
        !getQrData.isCheckout ? (
        <div className="share_referral text-center mb-5" style={{ padding: "20px" }}>
          <p className="text-extrabold fs-2" style={{ color: "#d3d3d3", marginBottom: "20px", fontWeight:600 }}>
            LDP 1 QR Code
          </p>
          <div
            style={{
              background: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              display: "inline-block",
            }}
          >
            <QRCode
              value={getQrData.qrCode}
              size={230}
              style={{ display: "block", margin: "0 auto" }}
            />
            <h5 className="mt-4 mb-0">{getQrData.qrCode}</h5>
            <span className="genrated-date">Genrated Date: {getQrData.qrGenratedDate}</span>
          </div>
        </div>
      ) : (
        <div className="ldp-page">
          <div className="container my-5">
            <Row>
              <Col
                lg={6}
                className="d-flex flex-row justify-content-center align-items-center"
              >
                <Form className="align-items-center w-100">
                  <>
                    <div className="mt-3">
                      {getQrData.qualifyCount >= 1 ? (
                        <h2 className="text-left ldp-msg text-capitalize ">
                          Congratulations You’ve successfully re-qualified for
                          LDP 1 Program.
                        </h2>
                      ) : (
                        <h2 className="text-left ldp-msg text-capitalize">
                          Congratulations You’ve successfully qualified for LDP
                          1 Program.
                        </h2>
                      )}
                    </div>
                    <br />
                    <Row>
                      <Col lg={12} className="mb-4">
                        <Form.Group controlId="formMobile">
                          <Form.Label>
                            Mobile <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Form.Control
                            type="tel"
                            required
                            placeholder="Enter mobile number"
                            value={mobile}
                            onChange={(e) =>
                              setMobile(
                                loginData1.mobile ||
                                  e.target.value.replace(/[^0-9]/g, "")
                                )
                              }
                              disabled={loginData1.mobile ? true : false}
                              pattern="[0-9]*"
                            />
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        <Col lg={12}>
                          <Form.Group controlId="formAddress">
                            <Form.Label>
                              Postal Address{" "}
                              <span style={{ color: "red" }}>*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              required
                              placeholder="Enter address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                              disabled={loginData1.address ? true : false}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </>

                    {getQrData.qualifyCount >= 1 && (
                      <>
                        <Row className="mb-2">
                          <Col lg={12}>
                            <Form.Group
                              controlId="formPaymentOption"
                              className=""
                            >
                              <Form.Label>Payment Mode</Form.Label>
                              <Form.Select
                                value={paymentOption}
                                onChange={(e) => setPaymentOption(e.target.value)}
                                disabled={loginData1.paymentOption ? true : false}
                                className="py-2"
                                style={{
                                  backgroundColor: "transparent",
                                  color: "#858585",
                                }}
                              >
                                <option value="MNT">MNT</option>
                                <option value="USDT">USDT</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Row className="mb-4">
                          <div className="text-left text-white d-flex flex-column gap-1">
                            <h6 className="text-secondary">
                              BNB Balance:{" "}
                              <span
                                className="text-light"
                                style={{ fontWeight: 800 }}
                              >
                                {currentTokenBalance["BNB"] || 0} BNB
                              </span>
                            </h6>
                            <h6 className="text-secondary">
                              Current Balance:{" "}
                              <span
                                className="text-light"
                                style={{ fontWeight: 800 }}
                              >
                                {currentTokenBalance[paymentOption] || 0}{" "}
                                {paymentOption}
                              </span>
                            </h6>
                            <h6 className="text-secondary">
                              Payable Amount:{" "}
                              <span
                                className="text-light"
                                style={{ fontWeight: 800 }}
                              >
                                {payableAmount || 0} {paymentOption}
                              </span>
                            </h6>
                          </div>
                        </Row>
                      </>
                    )}
                    <Col className="d-flex justify-content-start mt-2">
                      <Button
                        variant="primary"
                        onClick={() => handleClick(1)}
                        className="me-2 btn-main2 px-4"
                        disabled={paymentLoader}
                      >
                        Agree & Save
                        {/* {paymentLoader ? <Spinner as="span" animation="border" size="sm" /> : 'Agree'} */}
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => handleClick(0)}
                        className="btn-main2 px-3"
                        disabled={paymentLoader}
                      >
                        Disagree
                        {/* {paymentLoader ? <Spinner as="span" animation="border" size="sm" /> : 'Disagree'} */}
                      </Button>
                    </Col>
                  </Form>
                </Col>
                <Col lg={6}>
                  <div className="ldp_Qualifier text-center mt-3">
                    <img
                      src="assets/images/ldp.png"
                      width={`80%`}
                      className="object-fit-contain"
                      style={{ height: "416px" }}
                    />
                  </div>
                </Col>
              </Row>

              <br />
            </div>
          </div>
        )
      }
    </>
  );
};

export default LDP1Qualifier;
