import React, { useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { FaAnglesUp, FaChevronLeft } from "react-icons/fa6";
import { Link } from "react-router-dom";

import { MdDone } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import config from "../../config/config";
import Dashboardheader from "../../directives/dashboardheader";
import { getAllNotificationAction, UpdateNotificationStatusAction } from "../../Action/user.action";
import moment from "moment/moment";
import { useNotification } from "./NotificationContext";
const NotificationView = () => {
  const [notificationData, setNotificationData] = useState([]);
  const { updateNotificationCount } = useNotification();

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;

  useEffect(() => {
    getNotificationForUser()
  }, [])


  const getNotificationForUser = async (isLoadMore = false) => {
    try {
      const data = {
        limit: limit,
        page: isLoadMore ? page + 1 : page
      }
      const res = await getAllNotificationAction(data)
      if (res.success) {
        if (isLoadMore) {
          setNotificationData(prev => [...prev, ...res.data]);
        } else {
          setNotificationData(res.data);
        }
        setHasMore(res.data.length === limit);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const UpdateNotificationCount = async (notificationData) => {
    if (notificationData?.users?.[0]?.read_date !== null) {
      return;
    }
    try {
      
      const data = {
        notification_id: notificationData.id
      }
      const res = await UpdateNotificationStatusAction(data)
      if (res.success) {
        getNotificationForUser()
        updateNotificationCount();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
    getNotificationForUser(true);
  };

  return (
    <>
      <Dashboardheader />
      <div className="page-wrapper">
        <div className="position-relative container overflow-hidden mt-5 pt-2 mb-5">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="text-white ps-3" style={{ fontWeight: 600 }}>
              All Notifications{" "}
            </h2>
          </div>
          <br />
          <Row className="gy-3 mx-0">

            {notificationData.map((notification, index) => {
              const isUnread = notification?.users?.[0]?.read_date == null;
              return (
                <>
                  <Col key={index} lg={12} className={`align-items-start d-flex gap-3 py-3 px-4 w-100  position-relative ${isUnread ? 'notification-row' : 'notification-read'}`}
                    onClick={() => UpdateNotificationCount(notification)} style={{ cursor: "pointer", borderBottom:"1px solid #ffffff50" , borderRadius:10 }}>

                      <div className="mt-1" style={{  borderRadius:"50%",}}>
                      <img style={{width:30, height:30,}}  src="assets/images/stepmint-icon.png" />
                      </div>
                    {isUnread ?
                      <div style={{ width: 7, height: 7, backgroundColor: "#ff00007a", borderRadius: "50%", position: "absolute", top: 10, left: 8, cursor: "pointer" }}></div>
                      : ""}
                   <div style={{width:"95%"}}>
                   <p
                      className="mb-0 "
                      style={{
                        fontSize: 18,
                        wordBreak: "break-word",
                        fontWeight: "semibold",
                        color: "white",
                        textTransform: "capitalize",
                       wordWrap: "break-word",
                      }}
                    >
                      {notification.notifi_title}
                      <span className="ms-2 text-secondary" style={{ fontSize: 12 }}>    {moment(notification.date).format('MMMM Do YYYY, h:mm:ss a')}</span>
                    </p>
                    <p
                      className="mb-0  w-100 m-title mt-1"
                      style={{
                        fontSize: 12,
                        // wordBreak: "break-word",
                        fontWeight: 400,
                        // textOverflow: "ellipsis",
                        // overflow: "hidden",
                        // whiteSpace: "nowrap",
                        color: "#b1b1b1",
                      
                        wordWrap: "break-word",

                      }}
                    >
                      {notification.notifi_description}
                     
                    </p>
                   </div>

                  </Col>
                </>
              )
            })}

          </Row>
          {hasMore && notificationData.length > 0 && (
           
           <div className="text-center">
           <button type="button" class="btn btn-main2 text-white rounded-2 cust-logout btn btn-primary mt-4 px-4" onClick={handleLoadMore}>Load More</button>
           </div>
          )} 

          {notificationData.length === 0 && (
            <div className="text-center text-white mt-4">
              No notifications found
            </div>
            
          )}
          <div>

          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationView;