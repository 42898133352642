import React from "react";
import Header from "../../directives/header";
import Footer from "../../directives/footer";
import { Container, Row, Col, Button, Card, Tab, Nav, Modal } from "react-bootstrap";
import { Zoom, Fade, Slide, Roll } from "react-reveal";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "./css/home.css";
import HowItWorksMetaTag from "./../MetaTags/HowItWorksMetaTag";

const Howitwork = () => {


  // const navigateToPackagePlan = () => {

  //   navigate(`${config.baseUrl}packageplan`)
  // }

  return (
    <>
      <Header />
     <HowItWorksMetaTag />
      <div className="lighttheme bg-white overflow-hidden">
        <section className="herobanner position-relative text-white overflow-hidden">
          <div className="banner_bg">
            <img
              src="assets/images/banner-before.png"
              className="bannerbefore"
            />
          </div>
          <Container>
            <Row className="align-items-center">
              <Col lg={6}>
                <Fade left>
                  <div className="bannerContent ">
                    <h1>HOW STEPMINT WORKS</h1>
                    <p className="mt-4">
                      Walk your way to crypto riches with STEPMINT! This app pays you real cryptocurrency, MNT Tokens, just for strolling. STEPMINT uses GPS and sensors to track your steps. Download, create an account, and let the magic begin. Whether you carry your phone or sync it with a fitness tracker, hit your daily step goal and watch your MNT tokens grow. Here, fitness meets finance in the most delightful way possible!
                    </p>

                    {/* <Link>
                      <Button variant="main2" className="text-white px-5 mt-3">
                        Subscribe
                      </Button>
                    </Link> */}
                  </div>
                </Fade>
              </Col>
              <Col lg={6} className="text-center">
                <Zoom>
                  <div className="bannerImage position-relative">
                    <img src="assets/images/how_shoes.png" />
                  </div>
                </Zoom>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="ourmission bg-white pt-0">
          <Container className="bg-gradient rounded-5">
            <Row className="align-items-center justify-content-center p-5">

              <Col lg={8} className="text-center">
                <Slide right>
                  <h4 className=" mb-4 text-white">
                    <b>INTRODUCING STEPMINT</b>
                  </h4>
                  <p className="text-uppercase text-white">
                    our Path to Earning Crypto with Every Step!


                  </p>
                  <p className="text-light-grey">STEPMINT is more than just a step tracking app; it's a Web 3.0-powered revolution that rewards you for leading an active lifestyle.</p>

                </Slide>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="bg-white sport_community pt-3">
          <Container>
            <Row className="align-items-center mt-0 mb-5">
              <Col lg={12}>
                <div className="text-center">
                  <h1 className="text-purple text-uppercase"><b>Stepmint : Step-by-Step Process </b></h1>

                </div>
              </Col>
            </Row>
            <Row className="align-items-center pt-4 mt-5 mb-4">

              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 className="text-uppercase">
                      <b>Download and Create an Account-</b>
                    </h4>
                    <p className="mb-0">
                      Start by downloading the STEPMINT app and creating your account. It's easy and quick. Once you're in, you're ready to start earning!
                    </p>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/account_create.png" />
                </Fade>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="text-left">
                <Fade right>
                  <img src="assets/images/accurate_step.png" />
                </Fade>
              </Col>
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 className="text-uppercase">
                      <b>Accurate Step Tracking</b>
                    </h4>
                    <p className="mb-0">
                      Whether your device is in your pocket or synced with a fitness tracker, STEPMINT accurately tracks your daily steps. It's your virtual pedometer, ensuring every step counts towards your rewards.
                    </p>
                  </div>
                </Zoom>
              </Col>
            </Row>
            <Row className="align-items-center pt-4 mt-5 mb-4">

              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 className="text-uppercase">
                      <b>Effortless Reward Earning</b>
                    </h4>
                    <p className="mb-0">
                      As you go about your day, every step you take adds to your crypto rewards. No need to perform complex tasks or engage in exhausting activities! As Stepmint makes your earnings super effortless.
                    </p>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/effortless.png" />
                </Fade>
              </Col>
            </Row>
            <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="text-left">
                <Fade right>
                  <img src="assets/images/daily_step.png" />
                </Fade>
              </Col>
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 className="text-uppercase">
                      <b>Daily Step Goals</b>
                    </h4>
                    <p className="mb-0">
                      STEPMINT encourages you to set and achieve daily step goals. Challenge yourself and watch your crypto assets grow while improving your health.


                    </p>
                  </div>
                </Zoom>
              </Col>
            </Row>
            <Row className="align-items-center pt-4 mt-5 mb-4">

              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4 className="text-uppercase">
                      <b>Secure and Transparent Rewards</b>
                    </h4>
                    <p className="mb-0">
                      Your crypto assets are securely stored, and the rewards system operates on a transparent and decentralized blockchain network. You can monitor your earnings with confidence.



                    </p>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/secure.png" />
                </Fade>
              </Col>
            </Row>
            {/* <Row className="align-items-center mt-4 mb-4">
              <Col lg={6} className="">
                <Zoom>
                  <div className="sport-community-content">
                    <h4>
                      <b>HEALTH VERSUS WEALTH</b>
                    </h4>
                    <p className="">
                      1. The Pursuit of Wealth: Wealth often translates to
                      financial comfort, education, and personal growth
                      opportunities, encouraging a secure and fulfilling
                      lifestyle.
                    </p>
                    <p className="">
                      2. The Foundation of Health: Good physical and mental
                      health forms the basis of a fulfilling life, enabling one
                      to pursue goals, enjoy relationships, and enhance the
                      overall quality of life.
                    </p>
                    <p className="">
                      3. Balancing Act: Prioritizing one over the other—wealth
                      or health—can lead to stress and limitations. Finding
                      equilibrium allows the benefits of wealth to support a
                      healthy lifestyle and vice versa.
                    </p>
                    <p className="">
                      4. The Ideal Path: Striking the right balance ensures that
                      both wealth and health complement each other, resulting in
                      a life well-lived.
                    </p>
                  </div>
                </Zoom>
              </Col>
              <Col lg={6} className="text-right">
                <Fade right>
                  <img src="assets/images/health_versus.png" />
                </Fade>
              </Col>
            </Row> */}
          </Container>
        </section>
        <section className="getstarted bg-white p-0 pb-5">
          <Container className="bg-gradient position-relative">
            <Row className="align-items-center">

              <Col lg={12} className="">
                <div className="p-4">
                  <p className="text-white">Step out of your comfort zone to experience the best of both worlds: fitness and cryptocurrency! We are revolutionizing crypto with a fitness twist!  It's a user-friendly and innovative app that aligns your physical well-being with financial growth.
                    So, why wait? Download STEPMINT today, start tracking your steps, and watch your crypto assets flourish with each stride!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      <Footer />
    </>
  );
};
export default Howitwork;
