import React, { useState, useEffect } from "react";

const CountdownTimer = ({ timeDuration,is_withdraw_allow }) => {
  const [remainingTime, setRemainingTime] = useState("00:00:00");

  useEffect(() => {
    const targetTime = new Date(timeDuration).getTime();

    const updateRemainingTime = () => {
      const currentTime = new Date().getTime();
      const difference = targetTime - currentTime;
      if (difference <= 0) {
        setRemainingTime("00:00:00");
        
        clearInterval(timer);
        // if(is_withdraw_allow){
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 1000);
        // }
      } else {
        const totalHours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setRemainingTime(
          `${totalHours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
      }
    };

    const timer = setInterval(updateRemainingTime, 1000);
    updateRemainingTime(); // Call immediately to set the initial time

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [timeDuration]);

  return (
    <div>
      <span>Next Withdrawal Available In:</span> <span><b>{remainingTime}</b></span>
    </div>
  );
};

export default CountdownTimer;
