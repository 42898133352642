import React, { useEffect, useState } from "react";
import { Container, Row, Col, Tab, Nav, Spinner } from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  exportLoyaltyBounsData,
  getBusinessReportAction,
  MatchingTeamAndNodeList,
  ShowReferralHistory,
  showsteplevelrewardAction,
} from "../Action/user.action";
import CopyToClipboard from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import LoyaltyBonusDetails from "./LoyaltyBonusDetails";
import { useNavigate } from "react-router-dom";
import config from "../config/config";

const Matchingbonus = () => {
  const navigate = useNavigate();
  const [getreferral, setreferral] = useState([]);
  const [getnodelist, setnodelist] = useState([]);
  const [getteamlist, setteamlist] = useState([]);
  const [royaltybonuslist, setroyaltybonuslist] = useState([]);
  const [loadingTeamList, setLoadingTeamList] = useState(true); // Loading state for Team List
  const [loadingNodeList, setLoadingNodeList] = useState(true); // Loading state for Node List
  const [loadingReferralHistory, setLoadingReferralHistory] = useState(true);
  const [loadingBusinessReport, setLoadingBusinessReport] = useState(true);
  const [loadingRoyaltyBonus, setLoadingRoyaltyBonus] = useState(true); // Loading state for Royalty Bonus
  const [getreferralcount, setreferralcount] = useState(0);
  const [gettotalamountmnt, settotalamountmnt] = useState("");
  const [gettotalamountusdt, settotalamountusdt] = useState("");
  const [teamPurchase, setteamPurchase] = useState(0);
  const [totalEarening, setTotalEarening] = useState(0);
  const [loyaltyBonusDetailsStatus, setLoyaltyBonusDetailsStatus] = useState(false);
  const [loyaltyDetailsDate, setLoyaltyDetailsDate] = useState('');
  const [communityMessage, setCommunityMessage] = useState('');
  const [businessReportData, setBusinessReportData] = useState('');

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };

  useEffect(() => {
    const fetchData = async () => {
      await ReferralFunc();
      await showsteplevelFunc();
      await showmatchingandnodelistFunc("team"); // Call for team list
      await showmatchingandnodelistFunc("node"); // Call for node list
      await getBusinessReport();
    };

    fetchData();
  }, []);

  const getreferralSliced = getreferral?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const getnodelistSlice = getnodelist?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const getteamlistSlice = getteamlist?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
  const royaltybonuslistSlice = royaltybonuslist?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const copieBtn = async () => {
    toast.success(`Copied!!`);
  };

  const showsteplevelFunc = async () => {
    setLoadingRoyaltyBonus(true); // Set loading for royalty bonus
    try {
      let res2 = await showsteplevelrewardAction();
      if (res2.success) {
        setroyaltybonuslist(res2.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingRoyaltyBonus(false); // Reset loading for royalty bonus
    }
  };

  const showmatchingandnodelistFunc = async (type) => {
    if (type === "team") {
      setLoadingTeamList(true);
    } else if (type === "node") {
      setLoadingNodeList(true);
    }
    
    try {
      let res1 = await MatchingTeamAndNodeList();
      if (res1.success) {
        if (type === "team") {
          setteamlist(res1.data.teamList);
        } else if (type === "node") {
          setnodelist(res1.data.nodeList);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      if (type === "team") {
        setLoadingTeamList(false);
      } else if (type === "node") {
        setLoadingNodeList(false);
      }
    }
  };

  const ReferralFunc = async () => {
    setLoadingReferralHistory(true);
    try {
      let res = await ShowReferralHistory(2);
      if (res.success) {
        setreferral(res.data.history);
        setreferralcount(res.data.statistics.TeamCount);
        setteamPurchase(res.data.statistics.teamPurchase);
        setTotalEarening(res.data.statistics.totalmatchingearning || 0);
        setCommunityMessage(res.data.statistics.community_message);
        let totalamount = 0;
        for (let i = 0; i < res.data.history.length; i++) {
          totalamount += parseFloat(res.data.history[i].amount);
        }
        settotalamountmnt(totalamount);
        let totalamountUSDT = 0;
        for (let i = 0; i < res.data.history.length; i++) {
          totalamountUSDT += parseFloat(res.data.history[i].usdAmount);
        }
        settotalamountusdt(totalamountUSDT);
      } else {
        setreferral([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingReferralHistory(false);
    }
  };

  const handleViewDetails = async (date) => {
    let res = await exportLoyaltyBounsData({ date: date, page: 1, itemsPerPage: 10 });

    if (res.success) {
      if (!res.data.rows.length) {
        toast.error("No record found!");
      } else {
        const url = `${config.baseUrl}loyaltyBonusDetails/${date}`;
        window.open(url, '_blank'); // Opens the URL in a new tab
      }
    } else {
      toast.error("Failed to fetch data");
    }
  };

  const handleBusinessExpiry = async (data) => {
    const jsonData = { node_id: data.id, remaining_balance: data.remainingBalance, totalBusiness: data.totalBusiness };
    const queryString = new URLSearchParams(jsonData).toString();

    window.open(`${config.baseUrl}businessexpiry?${queryString}`, '_blank');
  };

  const handleUserBusinessReport = async (data) => {
    navigate(`${config.baseUrl}UserBusinessReport`, { state: { data } });
  };

  const getBusinessReport = async () => {
    setLoadingBusinessReport(true); // Set loading to true before fetching data
    try {
      const res = await getBusinessReportAction();
      if (res.success) {
        setBusinessReportData(res.data);
      }
    } catch (error) {
      // Handle error case
    } finally {
      setLoadingBusinessReport(false); // Set loading to false after fetching data
    }
  };


  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-3">
            <Container className=""></Container>
          </div>
          <div className="mb-5">
            <div className="container referral">
              <div className="mr-referral-content">
                <div className="mr-referral-block">
                  <h2 className="text-white" style={{ fontWeight: 600 }}>Community Rewards </h2>
                  <Row>
                    <Col lg={5} md={12} className="">
                      <div className="mr-referral-carditem mr-referralref-item buyform card-view1 py-5 mb-4">
                        <div className="mr-referral-inner">
                          <div className="mr-contant-block">
                            <h4>
                              <Row>
                                <Col lg={5} sm={5} xs={5}> Team Size </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex align-items-center">
                                    <span className="seprator"> : </span>
                                    <span>{getreferralcount}</span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                            <h4>
                              <Row>
                                <Col lg={5} sm={5} xs={5}> Team Purchase </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex align-items-center">
                                    <span className="seprator"> : </span>
                                    <span>${teamPurchase == null ? 0 : teamPurchase}</span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                            <h4 className="mb-0">
                              <Row>
                                <Col lg={5} sm={5} xs={5}> Total Income </Col>
                                <Col lg={7} sm={7} xs={7}>
                                  <div className="d-flex align-items-center">
                                    <span className="seprator"> : </span>
                                    <span>${totalEarening}</span>
                                  </div>
                                </Col>
                              </Row>
                            </h4>
                            <h6 className="mt-3">
                              <Row>
                                <Col lg={5} sm={5} xs={5}>
                                  <p className="mb-1">Note: </p>
                                </Col>
                                <div className="d-flex">
                                  <span className="text text-white">{communityMessage}</span>
                                </div>
                              </Row>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={7} md={12} className=" ">
                      <div className="mr-referral-carditem mr-referral-item buyform mb-4 card-view1 p-4">
                        <div className="mr-contant-block">
                          <img src="assets/images/mlm_tree.png" width="100%" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className="latest_transaction mb-5">
            <Container>
              <Tab.Container id="left-tabs-example" defaultActiveKey="teamlist">
                <Nav variant="pills" className="">
                  <Row className="text-center mb-4" style={{ width: "100vw" }}>
                    <Col lg={2} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="teamlist"
                          className="card-view1 customnav-link px-0"
                          onClick={CurrentPageResetFunc}
                        >
                          Team List
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={2} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="nodes"
                          className="card-view1 customnav-link px-0"
                          onClick={CurrentPageResetFunc}
                        >
                          Nodes
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={2} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="bonushistory"
                          className="card-view1 customnav-link px-0"
                          onClick={CurrentPageResetFunc}
                        >
                          Bonus History
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="royaltybonus"
                          className="card-view1 customnav-link px-0"
                          onClick={CurrentPageResetFunc}
                        >
                          Walkers Loyalty Reward
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={2} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="businessreport"
                          className="card-view1 customnav-link px-0"
                          onClick={CurrentPageResetFunc}
                        >
                          Business Report
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Row>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="teamlist">
                    {loadingTeamList ? ( // Check if loading
                      <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: 50 }}>
                        <Spinner animation="border" role="status" variant="primary">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table width="100%" className="text-center card-view">
                          <thead>
                            <tr className="table-head px-4">
                              <th style={{ width: "100px" }}>S.No</th>
                              <th>Email</th>
                              <th>Referred By</th>
                              <th>Purchase</th>
                              <th>Joining Date</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {getteamlist.length === 0 ? (
                              <tr className="tr_list">
                                <td colSpan="5">No Team Available</td>
                              </tr>
                            ) : (
                              getteamlistSlice.map((item, index) => (
                                <tr className="tr_list" key={index}>
                                  <td style={{ width: "100px" }}>
                                    {index + 1 + currentPage * itemsPerPage}
                                  </td>
                                  <td>{item.email}</td>
                                  <td>{item.referred_by}</td>
                                  <td>${item.user_purchase}</td>
                                  <td>{item.created_at}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {getteamlist.length > itemsPerPage && (
                          <div className="pagination-container">
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              previousClassName={currentPage === 0 ? "btn disabled" : ""}
                              nextClassName={currentPage === Math.ceil(getteamlist.length / itemsPerPage) - 1 ? "btn disabled" : ""}
                              previousLinkClassName={currentPage === 0 ? "disabled-link" : ""}
                              nextLinkClassName={currentPage === Math.ceil(getteamlist.length / itemsPerPage) - 1 ? "disabled-link" : ""}
                              breakLabel={"..."}
                              pageCount={Math.ceil(getteamlist.length / itemsPerPage)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="nodes">
                    {loadingNodeList ? ( // Check if loading
                      <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: 50 }}>
                        <Spinner animation="border" role="status" variant="primary">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table width="100%" className="text-center card-view">
                          <thead>
                            <tr className="table-head px-4">
                              <th style={{ width: "100px" }}>S.No</th>
                              <th>Email</th>
                              <th>Address</th>
                              <th>Total Business</th>
                              <th>Remaining Amount</th>
                              <th>Joining Date</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {getnodelist.length === 0 ? (
                              <tr className="tr_list">
                                <td colSpan="6">No Data Available</td>
                              </tr>
                            ) : (
                              getnodelistSlice.map((item, index) => (
                                <tr className="tr_list" key={index}>
                                  <td style={{ width: "100px" }}>
                                    {index + 1 + currentPage * itemsPerPage}
                                  </td>
                                  <td>{item.email}</td>
                                  <td>
                                    <span className="text-gradient">
                                      <b>
                                        <span className="text-sm text-bold text-light">
                                          {item.bnb_address?.slice(0, 4) + "...." + item.bnb_address?.slice(-4)}
                                        </span>
                                        <CopyToClipboard text={item.bnb_address}>
                                          <span
                                            title="Click to Copy"
                                            className="mr-copylink"
                                            id="token-buy-button"
                                            onClick={copieBtn}
                                            style={{ cursor: "pointer", color: "rgb(157 81 255)" }}
                                          >
                                            <i className="fa fa-clone"></i>
                                          </span>
                                        </CopyToClipboard>
                                      </b>
                                    </span>
                                  </td>
                                  <td>${item.totalBusiness}</td>
                                  <td>
                                    <div data-toggle="tooltip" data-placement="top" title="Click Here"
                                      onClick={() => item.remainingBalance > 0 && handleBusinessExpiry(item)}
                                      className={`px-3 py-1 ${item.remainingBalance > 0 && 'dash-gradient2'} text-white`}
                                      style={{ minWidth: "50px" }}>
                                      ${item.remainingBalance}
                                    </div>
                                  </td>
                                  <td>{item.created_at}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {getnodelist.length > itemsPerPage && (
                          <div className="pagination-container">
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              previousClassName={currentPage === 0 ? "btn disabled" : ""}
                              nextClassName={currentPage === Math.ceil(getnodelist.length / itemsPerPage) - 1 ? "btn disabled" : ""}
                              previousLinkClassName={currentPage === 0 ? "disabled-link" : ""}
                              nextLinkClassName={currentPage === Math.ceil(getnodelist.length / itemsPerPage) - 1 ? "disabled-link" : ""}
                              breakLabel={"..."}
                              pageCount={Math.ceil(getnodelist.length / itemsPerPage)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="bonushistory">
                    {loadingReferralHistory ? ( // Check if loading
                      <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: 50 }}>
                        <Spinner animation="border" role="status" variant="primary">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      <div className="table-responsive">
                        <table width="100%" className="text-center card-view">
                          <thead>
                            <tr className="table-head px-4">
                              <th style={{ width: "100px" }}>S.No</th>
                              <th>Date</th>
                              <th>Email</th>
                              <th>NFT Id</th>
                              <th>Community Rewards %</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {getreferral.length === 0 ? (
                              <tr className="tr_list">
                                <td colSpan="6">No History Available</td>
                              </tr>
                            ) : (
                              getreferralSliced.map((item, index) => (
                                <tr className="tr_list" key={index}>
                                  <td style={{ width: "100px" }}>
                                    {index + 1 + currentPage * itemsPerPage}
                                  </td>
                                  <td>{item.datetime}</td>
                                  <td>{item.user}</td>
                                  <td>{item.uniqueid}</td>
                                  <td>
                                    {item.referralPercent == null ? "NA" : item.referralPercent}%
                                  </td>
                                  <td>
                                    ${parseFloat(item.usdAmount)?.toFixed(2)}~
                                    {parseFloat(item.amount)?.toFixed(2)} MNT
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {getreferral.length > itemsPerPage && (
                          <div className="pagination-container">
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              previousClassName={currentPage === 0 ? "btn disabled" : ""}
                              nextClassName={currentPage === Math.ceil(getreferral.length / itemsPerPage) - 1 ? "btn disabled" : ""}
                              previousLinkClassName={currentPage === 0 ? "disabled-link" : ""}
                              nextLinkClassName={currentPage === Math.ceil(getreferral.length / itemsPerPage) - 1 ? "disabled-link" : ""}
                              breakLabel={"..."}
                              pageCount={Math.ceil(getreferral.length / itemsPerPage)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="royaltybonus">
                    {loadingRoyaltyBonus ? ( // Check if loading
                      <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: 50 }}>
                        <Spinner animation="border" role="status" variant="primary">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    ) : (
                      !loyaltyBonusDetailsStatus &&
                      <div className="table-responsive">
                        <table width="100%" className="text-center card-view">
                          <thead>
                            <tr className="table-head px-4">
                              <th style={{ width: "100px" }}>S.No</th>
                              <th>Date</th>
                              <th>Total NFT</th>
                              <th>Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {royaltybonuslist.length === 0 ? (
                              <tr className="tr_list">
                                <td colSpan="6">No History Available</td>
                              </tr>
                            ) : (
                              royaltybonuslistSlice.map((item, index) => (
                                <tr className="tr_list" key={index}>
                                  <td style={{ width: "100px" }}>
                                    {index + 1 + currentPage * itemsPerPage}
                                  </td>
                                  <td>{item.date2}</td>
                                  <td>{item.count}</td>
                                  <td>
                                    {parseFloat(item.amount).toFixed(4)} MNT
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => handleViewDetails(item.date)}
                                      className="btn-main2 px-4"
                                      style={{ cursor: "pointer", minHeight: 35 }}
                                    >
                                      View Details
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        {royaltybonuslist.length > itemsPerPage && (
                          <div className="pagination-container">
                            <ReactPaginate
                              previousLabel="Previous"
                              nextLabel="Next"
                              previousClassName={currentPage === 0 ? "btn disabled" : ""}
                              nextClassName={currentPage === Math.ceil(royaltybonuslist.length / itemsPerPage) - 1 ? "btn disabled" : ""}
                              previousLinkClassName={currentPage === 0 ? "disabled-link" : ""}
                              nextLinkClassName={currentPage === Math.ceil(royaltybonuslist.length / itemsPerPage) - 1 ? "disabled-link" : ""}
                              breakLabel={"..."}
                              pageCount={Math.ceil(royaltybonuslist.length / itemsPerPage)}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={handlePageChange}
                              containerClassName={"pagination"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {loyaltyBonusDetailsStatus && <LoyaltyBonusDetails loyaltyDetailsDate={loyaltyDetailsDate} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="businessreport">
                    {!loyaltyBonusDetailsStatus &&
                      <div className="table-responsive">
                        {loadingBusinessReport ? ( // Check if loading
                          <div className="d-flex justify-content-center align-items-center mt-3" style={{ height: 50 }}>
                            <Spinner animation="border" role="status" variant="primary">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                          </div>
                        ) : (
                          <table width="100%" className="text-center card-view">
                            <thead>
                              <tr className="table-head px-4">
                                <th style={{ width: "100px" }}>S.No</th>
                                <th>Business Date</th>
                                <th>Direct Node</th>
                                <th>Team Business (MNT)</th>
                                <th>Team Business (USDT)</th>
                                <th>Team Withdrawal (MNT)</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              {businessReportData.length === 0 ? (
                                <tr className="tr_list">
                                  <td colSpan="6">No History Available</td>
                                </tr>
                              ) : (
                                businessReportData.map((item, index) => (
                                  <tr className="tr_list" key={index}>
                                    <td style={{ width: "100px" }}>
                                      {index + 1}
                                    </td>
                                    <td>{item.business_date}</td>
                                    <td>{item.email}</td>
                                    <td>{item.business_mnt}</td>
                                    <td>{item.business_usd}</td>
                                    <td>{item.withdraw}&nbsp;</td>
                                    <td>
                                      <button
                                        onClick={() => handleUserBusinessReport(item)}
                                        className="btn-main2 px-4"
                                        style={{ cursor: "pointer", minHeight: 35 }}
                                      >
                                        View Details
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>}
                    {loyaltyBonusDetailsStatus && <LoyaltyBonusDetails loyaltyDetailsDate={loyaltyDetailsDate} />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Matchingbonus;