// src/component/WalletModal.js
import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";

const WalletModal = ({
  show,
  handleClose,
  handleWalletWithdrawl,
  handleReferralWithdrawlAction,
  handleVestingWithdrawlAction,
  handleStakingAction,
  handleBusinessWalletTransferAction,
  userData,
}) => {
  return (
    <Modal
      show={show}
      className="wallet-modal"
      size="lg"
      onHide={handleClose}
      centered
    >
      <Modal.Body className="d-flex flex-column align-items-center">
        <div className="wallet-main">
          <div className="wallet-header py-2 px-3">Select Wallet</div>

          <div className="px-3 py-3">
            <div className="wallet-card gap-2 mt-2 align-items-center px-3 py-3 position-relative overflow-hidden d-block">
              <div>
                <div className="d-flex d-sm-flex justify-content-between align-items-center mb-2">
                  <p
                    className="text-white mb-1 wallet-modal-data1"
                    style={{ fontWeight: 700 }}
                  >
                    <span className="me-1">
                      <img
                        src="assets/images/walletback1.svg"
                        style={{
                          position: "absolute",
                          right: 0,
                          opacity: 0.5,
                          top: 0,
                          zIndex: 0,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/walletback2.svg"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          opacity: 0.5,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/wallet02.png"
                        alt=""
                        style={{ width: 35, height: 35 }}
                      />
                    </span>
                    StepMint Wallet
                  </p>
                  <p
                    className="text-white mb-0 wallet-modal-data2 text-end"
                    style={{ fontWeight: 700 }}
                  >
                    <img src="assets/images/" alt="" />
                    {userData.step_wallet_balance}{" "}
                    <span style={{ fontSize: 9 }}>MNT</span>
                  </p>
                </div>

                {/* <div
                  className="d-flex justify-content-between mb-1"
                  style={{ position: "relative" }}
                > */}

                <Row className="justify-content-between">
                  <Col xl={4} lg={4} className="mb-2 mb-md-0">
                    <button
                      onClick={handleWalletWithdrawl}
                      className="text-white px-3 px-sm-5 btn btn-main2 maxamount2 rounded-2 mb-2 mb-md-0 w-100"
                      style={{ fontSize: 12 }}
                      type="button"
                    >
                      <img src="assets/images/icon/withdraw.png" style={{ width: 20, height: 20 }}
                        className="me-2"
                      /> Withdraw
                    </button>
                  </Col>

                  <Col xl={4} lg={4} className="mb-2 mb-md-0"></Col>
                </Row>
                {/* </div> */}
              </div>
            </div>
            <div className="wallet-card mt-3 gap-2 align-items-center px-3 py-3 position-relative overflow-hidden d-block">
              <div>
                <div className="d-flex d-sm-flex justify-content-between align-items-center mb-2">
                  <p
                    className="text-white mb-1 wallet-modal-data1"
                    style={{ fontWeight: 700 }}
                  >
                    <span className="me-1">
                      <img
                        src="assets/images/walletback1.svg"
                        style={{
                          position: "absolute",
                          right: 0,
                          opacity: 0.5,
                          top: 0,
                          zIndex: 0,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/walletback2.svg"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          opacity: 0.5,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/walletnew.png"
                        alt=""
                        style={{ width: 35, height: 35 }}
                      />
                    </span>
                    Business Wallet
                  </p>
                  <p
                    className="text-white mb-0 wallet-modal-data1 text-end"
                    style={{ fontWeight: 700 }}
                  >
                    {userData.referral_wallet_balance}{" "}
                    <span style={{ fontSize: 9 }}>MNT</span>
                  </p>
                </div>

                {/* <div
                  className="d-flex justify-content-between mb-1"
                  style={{ position: "relative" }}
                > */}


                <Row className="justify-content-between">
                  <Col xl={4} lg={4} className="mb-2 mb-md-0">
                    <button
                      onClick={handleReferralWithdrawlAction}
                      className="text-white px-3 px-sm-5 btn btn-main2 maxamount2 rounded-2 w-100"
                      style={{ fontSize: 12 }}
                      type="button"
                    >
                      <img src="assets/images/icon/withdraw.png" style={{ width: 20, height: 20 }}
                        className="me-2"
                      /> Withdraw
                    </button>
                  </Col>

                  <Col xl={4} lg={4}>
                    <button
                      onClick={handleBusinessWalletTransferAction}
                      className=" text-white px-3 wallet px-sm-5 btn btn-main2 maxamount2 rounded-2 w-100"
                      style={{ fontSize: 12 }}
                      type="button"
                    >
                      Transfer
                    </button>
                  </Col>
                </Row>
                {/* </div> */}
              </div>
            </div>
            <div className="wallet-card mt-3 mb-2 gap-2 align-items-center px-3 py-3 position-relative overflow-hidden d-block">
              <div>
                <div className="d-flex d-sm-flex justify-content-between align-items-center mb-2">
                  <p
                    className="text-white mb-1 wallet-modal-data1"
                    style={{ fontWeight: 700 }}
                  >
                    <span className="me-1">
                      <img
                        src="assets/images/walletback1.svg"
                        style={{
                          position: "absolute",
                          right: 0,
                          opacity: 0.5,
                          top: 0,
                          zIndex: 0,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/walletback2.svg"
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          opacity: 0.5,
                        }}
                        alt=""
                      />
                      <img
                        src="assets/images/walletnew.png"
                        alt=""
                        style={{ width: 35, height: 35 }}
                      />
                    </span>
                    Vesting Wallet
                  </p>
                  <p
                    className="text-white mb-0 wallet-modal-data2 text-end"
                    style={{ fontWeight: 700 }}
                  >
                    {userData.vesting_wallet_balance}{" "}
                    <span style={{ fontSize: 9 }}>MNT</span>
                  </p>
                </div>

                {/* <div
                  className="d-flex justify-content-between mb-1"
                  style={{ position: "relative" }}
                > */}
                <Row className="justify-content-between">
                  <Col xl={4} lg={4} className="mb-2 mb-md-0">
                    <button
                      onClick={handleStakingAction}
                      className="text-white px-3 px-sm-5 btn btn-main2 maxamount2 rounded-2 w-100"
                      style={{ fontSize: 12 }}
                      type="button"
                    >
                      Stake
                    </button>
                  </Col>

                  <Col xl={4} lg={4}>

                    <button
                      onClick={handleVestingWithdrawlAction}
                      className=" pull-right wallet text-white px-3 px-sm-5 btn btn-main2 maxamount2 rounded-2 w-100"
                      style={{ fontSize: 12 }}
                      type="button"
                    >
                      View Details
                    </button>
                  </Col>
                </Row>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WalletModal;
