import React, { useEffect, useState } from "react";
import Dashboardheader from "../../directives/dashboardheader";
import { useLocation, useNavigate } from "react-router-dom";
import config from "../../config/config";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  displayAlphaPackages,
  DisplaySubscription,
} from "../../Action/user.action";
import Cookies from "js-cookie";

import toast from "react-hot-toast";
const AlphaPackages = () => {
  const [getplan, setplan] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
  useEffect(() => {
    getPackagePlan();
  }, []);

  const redirection = () => {
    navigate(`${config.baseUrl}dashboard#subscribedplan`);
  };

  const redirection2 = (data) => {
    if (loginData && loginData.authToken) {
      navigate(`${config.baseUrl}alphanftdetail/${data}`, { state: { renewedFor: state.renewedId } });
    } else {
      toast.error("Access denied: Please log in to proceed.");
      // navigate(`${config.baseUrl}wallethistory`);
    }
  };

  const getPackagePlan = async () => {
    if (loginData.id) {
      let res = await displayAlphaPackages({ renewedFor: state.renewedId });

      if (res.success) {
        setplan(res.data);
      } else {
        setplan([]);
      }
    } else {
      let res = await DisplaySubscription();
      if (res.success) {
        setplan(res.data);
      } else {
        setplan([]);
      }
    }
  };

  if (!state.isAlpha) {
    return (
      <div
        className="text-center d-flex justify-content-center align-items-center flex-column "
        style={{
          padding: "20px",
          color: "#d3d3d3",
          borderRadius: "8px",
          height: "80vh",
        }}
      >
        <img style={{ width: "auto", height: "auto" }} src='assets/images/nodata2.png' />
        <h4>No data found</h4>
      </div>
    )
  }
  console.log(getplan)
  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>

      {state.isAlpha && <div className="page-wrapper">
        <div className="position-relative overflow-hidden position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* Basic NFT */}
          <div>
            <Container className="">
              <div>
                <h2 className="text-white" style={{ fontWeight: 600 }}>
                  Alpha Packages
                </h2>
              </div>

              <Row className="pt-2">
                {getplan
                  .filter(
                    (item) => item.editiontypeid == 3 || item.editionTypeId == 3
                  )
                  .map((item, index) => (
                    <Col lg={4} md={6} key={index} className="mb-4">
                      <div className="card nftplan">
                        <div className="card-body px-3 pt-1">
                          {/* <div
                                      className="btn-sm mh-auto position-absolute validity-time px-4 py-1"
                                      style={{
                                        borderRadius: "0px 25px 0px 25px",
                                        top: 0,
                                        fontSize:14,
                                        right: 0,
                                      }}
                                    >
                                     Validity: 1 Year
                                    </div> */}
                          <div className="pt-3 pb-1 px-2 ">
                            <h5 className="mb-0 text-white ">
                              {item.name}
                            </h5>

                            <div className="d-flex gap-2 justify-content-between mb-3 mt-2">
                              <div>
                                <p
                                  style={{ fontSize: 14 }}
                                  className="mb-0 px-3 py-1 w-auto"
                                >
                                  ${item.price}
                                </p>
                              </div>
                              <div>
                                {item.purchased == 1 ? (
                                  <Button
                                    variant="main2"
                                    onClick={redirection}
                                    className="rounded-1 btn-sm mh-auto px-4 "
                                    style={{ fontSize: 14 }}
                                  >
                                    Subscribed
                                  </Button>
                                ) : (
                                  <Button
                                    disabled={item.isdisable}
                                    variant="main2"
                                    onClick={() => redirection2(item.id)}
                                    className="rounded-1 btn-sm mh-auto px-4 "
                                    style={{ fontSize: 14 }}
                                  >
                                    Buy Now
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="card-view position-relative text-center p-2 rounded-30">
                            {
                              item.isdisable && <div className="custom-block">

                              </div>
                            }


                            <img
                              src={`${config.ipfsurl + item.image}`}
                              alt={item.image}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>
            </Container>
          </div>
        </div>
      </div>}
    </>
  );
};

export default AlphaPackages;
