import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "swiper/css/navigation";
import "../css/home.css";
import { getcmsAction } from "../../../Action/user.action";

const AlphaPlanTermsAndConditions = () => {
  const [termsandconditionlist, settermsandconditionlist] = useState({
    planterms:""
  });
  useEffect(() => {
    getTermsAndConditionFunc();
    // setcondition();
  }, []);

  const getTermsAndConditionFunc = async () => {
    let res = await getcmsAction("alphatnc");
    if (res.success) {
      settermsandconditionlist(res.data);
      console.log(res.data);
    } else {
    }
  };

  return (
    <>
      <div className="lighttheme overflow-hidden hero" style={{ minHeight: "100vh" }} >
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: termsandconditionlist.alphatnc,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>

      
    </>
  );
};
export default AlphaPlanTermsAndConditions;
