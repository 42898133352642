import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../config/config";
import {
  Container,
  Nav,
  Modal,
  Navbar,
  Offcanvas,
  Button,
  Row,
  Col,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../directives/header.css";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import Text from "../constant/constants";

import { RegisterAction } from "../Action/user.action";

const Header = () => {
  const [islogin, setislogin] = useState(false);
  const [show, setShow] = useState(false);
  const [lastparturl, setlastparturl] = useState([]);

  useEffect(() => {
    dynamicLink();
  }, []);

  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );

  const dynamicLink = () => {
    let url = window.location.href;
    const parts = url.split("/");
    const lastPart = parts[parts.length - 1];
    setlastparturl(lastPart);
  };

  const redirection = () => {
    navigate(`${config.baseUrl}dashboard`);
  };
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
  };
  const navigateToSignup = () => {
    setShow(false);
    setislogin(false);
    navigate(`${config.baseUrl}signup`);
  };
  const connectMetasmaskForAddress = async () => {
    setShow(false);
    try {
      if (window.ethereum) {
        setislogin(true);
        var web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let chainId = await web3.currentProvider.chainId;
        if (!chainId) {
          chainId = web3.currentProvider.getChainId();
        }
        if (chainId !== config.chainId && web3.utils.toHex(parseInt(chainId)) !== config.chainId) {
          toast.error(`Please select BNB smartchain!!`);
          setislogin(false);
          return false;
        } else {
          if (accounts[0]) {
            let publicAddress = accounts[0];
            const { signature } = await new Promise((resolve, reject) =>
              web3.eth.personal.sign(
                web3.utils.fromUtf8(`Login Mrmint`),
                publicAddress,
                (err, signature) => {
                  if (err) return reject(err);
                  return resolve({ publicAddress, signature });
                }
              )
            );
            let res = await RegisterAction({
              address: accounts[0],
              signature: signature,
            });
            if (res.success) {
              setislogin(false);

              toast.success(res.msg);
              Cookies.set("loginSuccessStepMrMint", JSON.stringify(res.data));
              setTimeout(() => {
                // navigate(`${config.baseUrl}dashboard`)
                window.location.href = `${config.baseUrl}dashboard`;
              }, 2000);
            } else {
              setislogin(false);
              toast.error(res.msg);
            }
          }
        }
      } else {
        setislogin(false);
        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      setislogin(false);
      switch (error.code) {
        case 4001:
          toast.error("User Rejected");
          break;
        case -32002:
          toast.error(
            "Request Already Processing, Please check your Meta Mask wallet"
          );
          break;
        case "ERR_NETWORK":
          toast.error("We're experiencing technical difficulties.");
          break;
        default:
          break;
      }
    }
  };
  return (
    <>
      <header className={lastparturl === "packageplan"  ||  
      (lastparturl === "signup" || lastparturl === "login")  ? "custom_buyplans py-1" : " py-1"
      }>
        <Toaster />
        <Container className="p-0">
          {["xl"].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid>
                <Navbar.Brand as={Link} to={`${config.baseUrl}`}>
                  <img src={lastparturl === "packageplan" ? "assets/images/f-logo.png" : "assets/images/logo.png" &&
                    (lastparturl === "signup" || lastparturl === "login") 
                    ? "assets/images/f-logo.png" : "assets/images/logo.png"} alt="" />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls={`offcanvasNavbar-expand-${expand}`}
                />
                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title
                      id={`offcanvasNavbarLabel-expand-${expand}`}
                    >
                      <img src={lastparturl === "packageplan" ? "assets/images/logo.png" : "assets/images/logo.png"} alt="" />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-center flex-grow-1 pe-3">
                      <Link
                        as={Link}
                        to={`${config.baseUrl}aboutus`}
                        className={
                          lastparturl === "aboutus"
                            ? "active custom_navbar"
                            : "custom_navbar" &&
                              lastparturl === "packageplan"
                              ? " custom_navbar text-decoration-none custom-clr "
                              : "custom_navbar"
                                &&
                                (lastparturl === "signup" || lastparturl === "login") 
                                ? " custom_navbar text-decoration-none custom-clr "
                                : "custom_navbar"
                        }
                      >
                        About us
                      </Link>
                      <Link
                        as={Link}
                        to={`${config.baseUrl}howitwork`}
                        className={lastparturl === "howitwork" ? "active custom_navbar" : " custom_navbar" &&
                          lastparturl === "packageplan"
                          ? " text-decoration-none  custom_navbar custom-clr"
                          : "custom_navbar" &&
                            (lastparturl === "signup" || lastparturl === "login") 
                            ? " custom_navbar text-decoration-none custom-clr "
                            : "custom_navbar"}
                      >
                        How It Works
                      </Link>
                      <Link
                        as={Link}
                        to={`${config.baseUrl}contactus`}
                        className={lastparturl === "contactus" ? "active custom_navbar " : "custom_navbar" &&
                          lastparturl === "packageplan"
                          ? " custom_navbar text-decoration-none custom-clr "
                          : "custom_navbar" &&
                            (lastparturl === "signup" || lastparturl === "login") 
                            ? " custom_navbar text-decoration-none custom-clr "
                            : "custom_navbar"}
                      >
                        Contact Us
                      </Link>
                    </Nav>
                    {islogin ? (
                      <button
                        disabled
                        className="btn btn-primary"
                        style={{ height: "40px", width: 171 }}
                      >
                        Processing...
                      </button>
                    ) : !loginData.id ? (
                      <>
                        <Row className="d-flex flex-column gap-2 flex-lg-row">
                          <Col>
                          <Link
                          to={`${config.baseUrl}login`}
                          variant="main2"
                          style={{width:"max-content"}}
                          className="text-white px-4  main2 d-flex align-items-center text-decoration-none"
                        >
                          Login
                        </Link>
                          </Col>
                          <Col>
                          <Link
                         to={`${config.baseUrl}signup`}
                          variant="main2 "
                          style={{width:"max-content"}}
                          className="text-white px-4  main2 d-flex align-items-center text-decoration-none"
                        >
                          Sign Up
                        </Link></Col>
                        </Row>
                      
                      </>
                    ) : (
                      <Button
                        onClick={() => redirection()}
                        variant="main2"
                        className="text-white px-4"
                        style={{ height: "40px", width: 171 }}
                      >
                        Dashboard
                      </Button>
                    )}
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          className="Nftmodal"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="btn-rounded pt-2 pb-3">
            <Row className="justify-content-center py-4">
              <Col lg={8}>
                <div className="text-center mb-3">
                  <Row className="align-items-center " >
                    <Col lg={12} className="mt-0 ">
                      <button
                        className="btn btn-main2 text-white btn-rounded px-4 rounded-1 w-100"
                        onClick={connectMetasmaskForAddress}
                      >
                        {/* <img alt="" src="assets/images/metamask_icon.png" height="28px"></img> &nbsp; */}
                        {Text.Connect_Wallet}
                      </button>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <span className=" btn-rounded px-4 rounded-5">or</span>
                    </Col>
                    <Col lg={12} className="mt-2">
                      <button
                        className="btn btn-main2 btn-sm text-white btn-rounded px-4 rounded-1 w-100"
                        onClick={navigateToSignup}
                      >
                        Signup
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      </header>
    </>
  );
};
export default Header;
