import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { getBusinessReportDetailsAction, getCsvFileBusinessReportDetailsAction } from "../Action/user.action";
import toast from "react-hot-toast";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CSVLink } from "react-csv";
import Dashboardheader from "../directives/dashboardheader";

const UserBusinessReport = () => {
    const [loading, setLoading] = useState(true);
    const [businessExpiryList, setBusinessExpiryList] = useState([]);
    const [csvData, setCsvData] = useState([]);
    const [priceData, setPriceData] = useState([]);
    const [csvReady, setCsvReady] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const reportData = location.state?.data;

    console.log(priceData, '++++');


    const queryParams = new URLSearchParams(location.search);

    // Convert query params back to JSON
    const node_id = queryParams.get("node_id");
    const remaining_balance = queryParams.get("remaining_balance");
    const totalBusiness = queryParams.get("totalBusiness");


    useEffect(() => {
        getBusinessReportDetails();
    }, []);

    const getBusinessReportDetails = async () => {
        setLoading(true)
        let res = await getBusinessReportDetailsAction(reportData.id);
        if (res.success) {
            if (res.success) {
                setBusinessExpiryList(res.data.details);
                setPriceData(res.data)
            } else {

                setBusinessExpiryList([]);
            }
        } else {
            toast.error("Failed to fetch data");
        }
        setLoading(false);
    };

    const columns = [
        {
            name: "S.No",
            selector: (row, index) => index + 1,
        },
        {
            name: "Date",
            selector: (row) => row.business_date,
        },
        {
            name: "Team Business (MNT)",
            selector: (row) => row.business_mnt,
        },
        {
            name: "Team Business (USDT)",
            selector: (row) => row.business_usd,
        },
        {
            name: "Team Withdrawl (MNT)",
            selector: (row) => row.withdraw,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: "72px", // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: "16px",
                paddingRight: "16px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px",
            },
        },
        headRow: {
            style: {
                borderRadius: "10px 10px 0px 0px",
                border: "none",
                color: "white",

                textAlign: "center",
                background:
                    "linear-gradient(174deg, rgba(65, 210, 255, 0.25) 0%, rgba(126, 66, 255, 0.25) 47.40%, rgba(221, 63, 255, 0.25) 96.19%)",
            },
        },
        cells: {
            style: {
                paddingLeft: "16px",
                paddingRight: "16px",
                wordBreak: "break-word",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "16px"
            },

        },
        table: {
            style: {
                color: "white",
                backgroundColor: "transparent",
            },
        },
        rows: {
            style: {
                fontSize: '13px',
                fontWeight: 400,
                color: "white",
                background: "linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%)",
                minHeight: '48px',

            },
        },
        pagination: {
            style: {
                fontSize: "13px",
                minHeight: "56px",
                background: "linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%)",
                borderBottom: "none",
                color: "white",
                // borderTopColor: "#99999931",
            },
            pageButtonsStyle: {
                borderRadius: "50%",
                height: "40px",
                width: "40px",
                padding: "8px",
                margin: "px",
                cursor: "pointer",
                transition: "0.4s",

                fill: "white",
                backgroundColor: "transparent",
                "&:disabled": {
                    cursor: "unset",

                    fill: "white",
                },
            },
        },
    };

    const handleBackNavigation = () => {
        navigate(-1);
    };
    // 📥 CSV Headers
    const csvHeaders = [
        // { label: "S.No", key: "sno" },
        { label: "Date", key: "business_date" },
        { label: "Team Business (MNT)", key: "business_mnt" },
        { label: "Team Business (USDT)", key: "business_usd" },
        { label: "Team Withdrawal (MNT)", key: "withdraw" },
    ];

    // 📥 Fetch and Prepare CSV Data when button is clicked
    const handleDownloadCSV = async () => {
        setLoading(true);
        let res = await getCsvFileBusinessReportDetailsAction(reportData.id);
        if (res.success) {
            const formattedData = res.data.map((row, index) => ({
                // sno: index + 1,
                business_date: row.business_date,
                business_mnt: row.business_mnt,
                business_usd: row.business_usd,
                withdraw: row.withdraw,
            }));

            setCsvData(formattedData);
            setCsvReady(true);

            // Trigger download after data is ready
            setTimeout(() => {
                document.getElementById("download-csv-btn").click();
            }, 500);
        } else {
            toast.error("Failed to fetch data for CSV");
        }
        setLoading(false);
    };

    return (
        <>
        <Dashboardheader />
            <div className="shape_bg darkkkk">
                <img src="assets/images/shape.svg" className="shape" />
                <img src="assets/images/shape1.svg" className="shape1" />
                <img src="assets/images/shape2.svg" className="shape2" />
            </div>
            <div className="page-wrapper">

                <Container className="mt-5 mb-3 positon-relative" >
                    <p
                        style={{
                            color: "white",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            position:"absolute",
                        }}
                        onClick={handleBackNavigation}
                    >
                        <IoIosArrowRoundBack size={35} />
                    </p>
                    <div className="mr-referral-block ">


                        <Row>
                            <Col lg={12} md={12} className="">
                                <div className="mr-referral-carditem mr-referralref-item buyform card-view1   mb-4">
                                    <div className="mr-referral-inner">
                                        <div className="mr-contant-block">
                                            <h2 className="text-white mb-4 ms-5">User Business Report Details</h2>
                                            <Row>
                                                <Col lg={6}>
                                                    <h4>
                                                        <Row>
                                                            <Col lg={4} sm={5} xs={5}>
                                                                {" "}
                                                                Email
                                                            </Col>
                                                            <Col lg={8} sm={7} xs={7}>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="seprator"> : </span>
                                                                    <span style={{ wordBreak: "break-all" }} className="text-wrap">{reportData.email}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </h4>
                                                </Col>

                                                <Col lg={6}>
                                                    <h4>
                                                        <Row>
                                                            <Col lg={5} sm={5} xs={5}>
                                                                {" "}
                                                                Team Business
                                                            </Col>
                                                            <Col lg={7} sm={7} xs={7}>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="seprator"> : </span>
                                                                    <span>{priceData.total_mnt||0} MNT</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </h4>
                                                </Col>

                                                <Col lg={6}>
                                                    <h4>
                                                        <Row>
                                                            <Col lg={4} sm={5} xs={5}>
                                                                {" "}
                                                                Team Business
                                                            </Col>
                                                            <Col lg={7} sm={7} xs={7}>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="seprator"> : </span>
                                                                    <span>{priceData.total_usd || 0} USDT</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </h4>
                                                </Col>

                                                <Col lg={6}>
                                                    <h4>
                                                        <Row>
                                                            <Col lg={5} sm={5} xs={5}>
                                                                {" "}
                                                                Team Size
                                                            </Col>
                                                            <Col lg={7} sm={7} xs={7}>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="seprator"> : </span>
                                                                    <span>{priceData.team_size || 0}</span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </h4>
                                                </Col>
                                            </Row>




                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {/* <Col lg={7} md={12} className=" ">
                                <div className="mr-referral-carditem mr-referral-item buyform mb-4 card-view1 ">
                                    <div className="mr-contant-block d-flex align-items-center justify-content-center">
                                        <img src="assets/images/mlm_tree.png" style={{ width: 200, height: 200 }} />
                                    </div>
                                </div>
                            </Col> */}
                        </Row>
                    </div>

                </Container>

                <div className="latest_transaction mb-5">
                    <Container>
                        <div className="w-100 d-flex justify-content-end ">
                            <Button
                                // variant="primary"
                                style={{ background: "linear-gradient(90deg,rgba(65, 210, 255, 0.1) 0%,rgba(126, 66, 255, 0.1) 47.4%,rgba(221, 63, 255, 0.1) 96.19%)", border: "1px solid #ffffff50", borderRadius: 10 }}
                                className="mb-3 px-4 "
                                onClick={handleDownloadCSV}
                                disabled={loading}
                            >
                                {loading ? "Generating CSV..." : "📥 Download CSV"}
                            </Button>
                        </div>

                        {/* Hidden CSVLink to trigger download */}
                        {csvReady && (
                            <CSVLink
                                data={csvData}
                                headers={csvHeaders}
                                filename={`${reportData.email}_Business_Report_${Date.now()}.csv`}
                                id="download-csv-btn"
                                className="d-none"
                                target="_blank"
                            />
                        )}
                        {loading ? (
                            <div className="d-flex justify-content-center align-items-center">
                                <Spinner animation="border" role="status" variant="primary">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                columns={columns}
                                data={businessExpiryList}
                                customStyles={customStyles}
                            pagination
                            />
                        )}
                    </Container>
                </div>
            </div>
        </>
    );
};

export default UserBusinessReport;
