import React, { useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';

const StakeHistory = ({ stakingHistory, loadMoreHistory }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handleLoadMore = () => {
        setCurrentPage(prevPage => prevPage + 1);
        loadMoreHistory(currentPage + 1);
    };

    return (
        <div>
            <h3>Staking History</h3>
            <br />
            {stakingHistory?.length > 0 ? (
                stakingHistory.map((history, index) => (
                    <Row key={index}>
                        <Col xl={12}>
                            <div
                                style={{
                                    backgroundColor: "#151515",
                                    borderRadius: 10,
                                    textTransform: "capitalize",
                                    marginBottom: "10px",
                                }}
                            >
                                
                                <Row className="gy-2 px-3 pt-3 mt-2 justify-content-between">
                                    <Col className="text-center">
                                        <p className="text-secondary wcard-title mb-2">Date</p>
                                        <p className="wcard-data">{history.date || "N/A"}</p>
                                    </Col>

                                    <Col className="text-center">
                                        <p className="text-secondary wcard-title mb-2">Stake Amount</p>
                                        <p className="">{history.stake_amount || "0"} MNT</p>
                                    </Col>

                                    <Col className="text-center">
                                        <p className="text-secondary wcard-title mb-2">Stake Plan</p>
                                        <p className="wcard-data">
                                            {history.duration_month ? `${history.duration_month} Months (${history.apy}% APR)` : "N/A"}
                                        </p>
                                    </Col>

                                    <Col className="text-center">
                                        <p className="text-secondary wcard-title mb-2">Release Date</p>
                                        <p className="wcard-data">{history.release_date || "N/A"}</p>
                                    </Col>

                                    <Col className="text-center">
                                        <p className="text-secondary wcard-title mb-2">Release Amount</p>
                                        <p className="wcard-data">{history.release_amount || "0"} MNT</p>
                                    </Col>

                                    <Col className="text-center d-flex align-items-baseline justify-content-center">
                                        <div className="option active-vesting dash-gradient d-flex align-items-center justify-content-center"
                                            style={{ width: 100, height: 40, fontSize: 14 }}>
                                            Claim
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                ))
            ) : (
                <p className="text-center text-secondary">No Stake History Available</p>
            )}
            {stakingHistory.length > 10 && (
                <div className="text-center mt-3">
                    <Button variant="main2" onClick={handleLoadMore}>
                        Load More
                    </Button>
                </div>
            )}
        </div>
    );
};

export default StakeHistory;