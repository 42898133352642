import config from "../config/config";
import { apiConstants } from "../constant/api.constant";
import { getRequest, postRequest, putRequest, deleteRequest, postRequestFormData } from "../directives/helper";

export const getFaqsAction = (data) => {
   return getRequest('getFAQslist', data).then(res => { return res.data })
}

export const RegisterAction = (data) => {
   return postRequest('weblogin', data).then(res => { return res.data })
}
export const ProfileUpdateAction = (data) => {
   return putRequest('profileupdate', data).then(res => { return res.data })
}
export const adressUpdateAction = (data) => {
   return putRequest('addressUpdate', data).then(res => { return res.data })
}


export const LdpUpdateAction = (data) => {
   return putRequest('ldpUpdate', data).then(res => { return res.data })
}

export const Ldp2UpdateAction = (data) => {
   return putRequest('ldp2Update', data).then(res => { return res.data })
}

export const GetOtpAction = (data) => {
   return getRequest('checkLDP1', data).then(res => { return res.data })
}

export const GetLdp2Action = (data) => {
   return getRequest('checkLDP2', data).then(res => { return res.data })
}

export const SendOtpAction = (data) => {
   return postRequest('sendLdpQualifierOtp', data).then(res => { return res.data })
}

export const VerifyOtpAction = (data) => {
   return postRequest('verifyLdpQualifierOtp', data).then(res => { return res.data })
}

export const VerifyQRCodeAction = (data) => {
   return postRequest('verifyQRCode', data).then(res => { return res.data })
}


export const ContactUsAction = (data) => {
   return postRequest('contactus', data).then(res => { return res.data })
}
export const checkmintingofNFTAction = (data) => {
   return getRequest(`checkmintingofNFT?nftId=${data}`).then(res => { return res.data })
}
export const LDPUserlist = (data) => {
   return getRequest('ldpList', data).then(res => { return res.data })
}
export const ldpListUpdateAction = (data) => {
   return postRequest('ldpListUpdate', data).then(res => { return res.data })
}
export const UserProfile = (data) => {
   return getRequest('userprofile', data).then(res => { return res.data })
}
export const deleteUserProfileAction = (data) => {
   return getRequest('deleteuser', data).then(res => { return res.data })
}
export const getcmsAction = (data) => {
   return getRequest(`getcms?show=${data}`).then(res => { return res.data })
}
export const ReferralUserList = (data) => {
   return getRequest('referraluserlist', data).then(res => { return res.data })
}
export const MatchingTeamAndNodeList = (data) => {
   return getRequest('matchingteamandnodelist', data).then(res => { return res.data })
}

export const userWeeklyStatisticsAction = (data) => {
   return getRequest('userweeklystatistics', data).then(res => { return res.data })
}

export const userMonthlyStatisticsAction = (data) => {
   return getRequest('usermonthlystatistics', data).then(res => { return res.data })
}

export const stepRewardHistoryAction = (data) => {
   return getRequest('steprewardhistory', data).then(res => { return res.data })
}

export const walletStatisticsAction = (data) => {
   return getRequest('walletstatistics', data).then(res => { return res.data })
}

export const userPerDayStatisticsAction = (data) => {
   return getRequest('userperdaystatistics', data).then(res => { return res.data })
}

export const balanceTransferStatisticsAction = (data) => {
   return getRequest('balancetransferstatistics', data).then(res => { return res.data })
}

export const DisplaySubscription = (data) => {
   return getRequest('displayallsubscriptionplan', data).then(res => { return res.data })
}

export const DisplaySelectedSubscriptionPlanId = (data) => {
   return getRequest(`displayselectedsubscriptionplan?id=${data}`).then(res => { return res.data })
}

export const purchasesubscription = (data) => {
   return getRequest(`purchasesubscription?planId=${data}`).then(res => { return res.data })
}

export const DisplayAllSubscriptionPlanForUser = (data) => {
   return getRequest('displayallsubscriptionplanforuser', data).then(res => { return res.data })
}

export const displayAlphaPackages = (data) => {
   return postRequest('displayAlphaPackages', data).then(res => { return res.data })
}


export const UserPurchasedPlan = (data) => {
   return getRequest('userpurchasedplan', data).then(res => { return res.data })
}

export const DashboardStatistics = (data) => {
   return getRequest('dashboardstatistics', data).then(res => { return res.data })
}


export const showsteplevelrewardAction = (data) => {
   return getRequest('showsteplevelreward', data).then(res => { return res.data })
}

export const MntDepositHistory = (data) => {
   return getRequest('mntdeposithistory', data).then(res => { return res.data })
}

export const ShowReferralHistory = (data) => {
   return getRequest(`showreferralORmatchingbonushistory?type=${data}`).then(res => { return res.data })
}

export const DepositWithdrwalHistory = (data) => {
   return postRequest(`withdrawalWallethistory`, data).then(res => { return res.data })
}

export const TransferToMainWalletAction = (data) => {
   return getRequest(`transfertomainwallet`, data).then(res => { return res.data })
}

export const WithdrawalHistoryAction = (data) => {
   return getRequest(`withdrawalhistory`, data).then(res => { return res.data })
}

export const addWithdrawRequestAction = (data) => {
   return postRequest(`stepWithdrawRequest`, data).then(res => { return res.data })
}

export const addmainwalletwithdrawrequestAction = (data) => {
   return postRequest(`addmainwalletwithdrawrequest`, data).then(res => { return res.data })
}

export const sendOtpForVerificationAction = (data) => {
   return postRequest(`sendEmailOtp`, data).then(res => { return res.data })
}
export const sendOtpForPhoneVerificationAction = (data) => {
   return postRequest(`sendPhoneOtp`, data).then(res => { return res.data })
}
export const verifyOtpForVerificationAction = (data) => {
   return postRequest(`verifyEmailAndOtp`, data).then(res => { return res.data })
}
export const verifyOtpForPhoneVerificationAction = (data) => {
   return postRequest(`verifyPhoneAndOtp`, data).then(res => { return res.data })
}

export const exportLoyaltyBounsData = (data) => {
   return postRequest('getLoyaltyBonusDetails', data).then(res => { return res.data })
}

export const getTransactionSummary = (data) => {
   return getRequest('getTransactionSummary', data).then(res => { return res.data })
}
// For Email and mobile  OTP-Verification for phase-2-------------------------------------

// For Email - -----

export const sendOtpForEmailVerificationAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.EMAIL_OTP_VERIFICATION}`, data).then(res => { return res.data })
}
export const verifyOtpForEmailVerificationAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.GENRATE_Email_VERIFICATION_OTP}`, data).then(res => { return res.data })
}

// For Mobile -----

export const sendOtpForMobileVerificationAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.GENRATE_PHONE_VERIFICATION_OTP}`, data).then(res => { return res.data })
}
export const verifyOtpForMobileVerificationAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.PHONE_OTP_VERIFICATION}`, data).then(res => { return res.data })
}
// User Registration Action ---------------------------------------------------
export const userRegistrationActionPhase2 = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.USER_REGISTRATER}`, data).then(res => { return res.data })
}
// Login User for Phase 2 ----------------------------------------------
export const LoginUserPhase2Action = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.USER_LOGIN}`, data).then(res => { return res.data })
}
// Forget Password for Phase 2 ----------------------------------------------
export const forgetpasswordAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.FORGOT_PASSWORD}`, data).then(res => { return res.data })
}
// Forget Password for Phase 2 ----------------------------------------------
export const resetpasswordAction = (data) => {
   return postRequest(`${config.PHASE2_URL}/${apiConstants.RESET_PASSWORD}`, data).then(res => { return res.data })
}

// Api calling For Notification-------------------------------------------------------------
export const getAllNotificationAction = (data) => {
   return postRequest(`${config.Notification}/${apiConstants.GET_NOTIFICATION_BY_USER}`, data).then(res => { return res.data })
}

// Api calling For Notification Count -------------------------------------------------------------
export const getAllNotificationCountAction = (data) => {
   return getRequest(`${config.Notification}/${apiConstants.GET_NOTIFICATION_COUNT_BY_ID}`, data).then(res => { return res.data })
}

// Api calling For Update Notification  -------------------------------------------------------------
export const UpdateNotificationStatusAction = (data) => {
   return postRequest(`${config.Notification}/${apiConstants.UPDATE_NOTIFICATION}`, data).then(res => { return res.data })
}

// Get withdrawal timer

export const getWithdrawalTimeAction = (data) => {
   return getRequest(`getWithdrawalTime`, data).then(res => { return res.data })
}
// get handle Vesting Wallet Data History ======================

export const handleVestingWalletDataHistory = (data) => {
   return postRequest(`vestingWalletHistory`, data).then(res => { return res.data })
}
export const getStepDataAction = (id) => {
   return getRequest(`withdrawWalletInfo/${id}`).then(res => res.data);
};

export const addReffWithdrawRequestAction = (data) => {
   return postRequest(`referralWithdrawRequest`, data).then(res => { return res.data })
}


// Api calling for Business Expiry get--------------------------------------------------------
export const fetchBusinessExpiryAction = (data) => {
   return postRequest(
      `${config.PHASE2_URL}/${apiConstants.GET_BUSINESS_EXPIRY}`,
      data
   ).then((res) => {
      return res.data;
   });
};


export const getVestingWallateHistoryAction = (data) => {
   return postRequest(`vestingWalletReleaseHistory`, data).then(res => { return res.data })
}
export const getStackingDetailsListAction = (data) => {
   return getRequest(`staking`, data).then(res => { return res.data })
}
export const getStackingHistoryListAction = (data) => {
   return postRequest(`stakingHistory`, data).then(res => { return res.data })
}
export const submitStakingAction = (data) => {
   return postRequest(`submitStaking`, data).then(res => { return res.data })
}
export const getWalletBalanceAction = (data) => {
   return getRequest(`transfer`, data).then(res => { return res.data })
}
export const getTransferHistoryAction = (data) => {
   return postRequest(`businessTransferHistory`, data).then(res => { return res.data })
}
export const transferAmountAction = (data) => {
   return postRequest(`businessTransfer`, data).then(res => { return res.data })
}
export const getBusinessReportAction = (data) => {
   return getRequest(`businessReport`, data).then(res => { return res.data })
}
export const getBusinessReportDetailsAction = (id) => {
   return getRequest(`businessDetails/${id}`).then((res) => {
     return res.data;
   });
 };
 
 export const getCsvFileBusinessReportDetailsAction = (id) => {
   return getRequest(`allBusinessData/${id}`).then((res) => {
     return res.data;
   });
 };