import React, { useEffect, useState } from "react";
import {
  Container,
  Row
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../css/home.css";
import { getcmsAction } from "../../../Action/user.action";

const Disclaimer = () => {
  const [disclaimer, setdisclaimer] = useState({
    disclaimer:""
  });
  useEffect(() => {
    disclaimerFun();
    // setcondition();
  }, []);

  const disclaimerFun = async () => {
    let res = await getcmsAction("disclaimer");
    if (res.success) {
      setdisclaimer(res.data);
      console.log(res.data);
    } else {
    }
  };

  // const navigateToPackagePlan = () => {

  //   navigate(`${config.baseUrl}packageplan`)
  // }

  return (
    <>
      <div className="lighttheme overflow-hidden hero" >
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: disclaimer.disclaimer,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>

      
    </>
  );
};
export default Disclaimer;
