import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stepWalletData: [],
    RefWalletData: [],
};

export const StepwalletSlice = createSlice({
    name: "stepWalletData",
    initialState,
    reducers: {
        setStepwalletReducers: (state, action) => {
            if (state.stepWalletData.length === 0) {
                state.stepWalletData = action.payload.data; 
            } else {
                state.stepWalletData = [...state.stepWalletData, ...action.payload.data];
            }
        },
    },
});

export const { setStepwalletReducers } = StepwalletSlice.actions;
export default StepwalletSlice.reducer;
