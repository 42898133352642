export const apiConstants = {

    USER_LOGIN: 'weblogin',

    USER_REGISTRATER: 'userRegister',
    PHONE_OTP_VERIFICATION: 'verifyPhoneRegistrationOtp',
    GENRATE_PHONE_VERIFICATION_OTP: 'generatePhoneRegistrationOtp',
    FORGOT_PASSWORD: 'forgotPassword',
    RESET_PASSWORD: 'resetPassword',
    EMAIL_OTP_VERIFICATION: 'generateEmailRegistrationOtp',
    GENRATE_Email_VERIFICATION_OTP: 'verifyEmailRegistrationOtp',
    GET_BUSINESS_EXPIRY: "businessExpiryList",
    // EndPoints for Notification-------------
    GET_NOTIFICATION_BY_USER: 'getNotificationByUser',
    GET_NOTIFICATION_COUNT_BY_ID: 'getNotificationCountByUserId',
    UPDATE_NOTIFICATION: 'updateNotificationByUserId'


}