import React, { useEffect, useState } from "react";
import { Col, Container, Nav, Row, Tab, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useLocation, useParams } from "react-router-dom";
import { fetchBusinessExpiryAction } from "../Action/user.action";
import toast from "react-hot-toast";

const BusinessExpiry = () => {

  const [loading, setLoading] = useState(true);
  const [businessExpiryList, setBusinessExpiryList] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Convert query params back to JSON
  const node_id = queryParams.get("node_id");
  const remaining_balance = queryParams.get("remaining_balance");
  const totalBusiness = queryParams.get("totalBusiness");


  useEffect(() => {
    getBusinessExpiry();
  }, []);

  const getBusinessExpiry = async () => {
    setLoading(true)
    let res = await fetchBusinessExpiryAction({ node_id, remaining_balance });

    if (res.success) {
      if (!res.data.length) {
        toast.error("No record found!");
        setBusinessExpiryList([]);
      } else {
        setBusinessExpiryList(res.data);
      }
    } else {
      toast.error("Failed to fetch data");
    }
    setLoading(false);
  };

  const columns = [
    // {
    //   name: "S.No",
    //   selector: (row) => row.id,
    // },
    {
      name: "Effective From",
      selector: (row) => row.purchase_date,
    },
    {
      name: "Amount",
      selector: (row) => row.remaining_amount,
    },
    {
      name: "Expiry Date",
      selector: (row) => row.expiry_date,
    },
  ];


  const customStyles = {
    rows: {
      style: {
        minHeight: "72px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px",
      },
    },
    headRow: {
      style: {
        borderRadius: "10px 10px 0px 0px",
        border: "none",
        color: "white",

        textAlign: "center",
        background:
          "linear-gradient(174deg, rgba(65, 210, 255, 0.25) 0%, rgba(126, 66, 255, 0.25) 47.40%, rgba(221, 63, 255, 0.25) 96.19%)",
      },
    },
    cells: {
      style: {
        paddingLeft: "16px",
        paddingRight: "16px",
        wordBreak: "break-word",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "16px"
      },

    },
    table: {
      style: {
        color: "white",
        backgroundColor: "transparent",
      },
    },
    rows: {
      style: {
        fontSize: '13px',
        fontWeight: 400,
        color: "white",
        background: "linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%)",
        minHeight: '48px',

      },
    },
    pagination: {
      style: {
        fontSize: "13px",
        minHeight: "56px",
        background: "linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%)",
        borderBottom: "none",
        color: "white",
        // borderTopColor: "#99999931",
      },
      pageButtonsStyle: {
        borderRadius: "50%",
        height: "40px",
        width: "40px",
        padding: "8px",
        margin: "px",
        cursor: "pointer",
        transition: "0.4s",

        fill: "white",
        backgroundColor: "transparent",
        "&:disabled": {
          cursor: "unset",

          fill: "white",
        },
      },
    },
  };

  return (
    <>
      <div className="shape_bg darkkkk">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
      </div>
      <div className="page-wrapper">
        <Container className="my-5">

          <div className="mr-referral-block">


            <Row>
              <Col lg={5} md={12} className="">
                <div className="mr-referral-carditem mr-referralref-item buyform card-view1   mb-4">
                  <div className="mr-referral-inner">
                    <div className="mr-contant-block">
                      <h2 className="text-white mb-4">Business Expiry</h2>
                      <h4>
                        <Row>
                          <Col lg={5} sm={5} xs={5}>
                            {" "}
                            Total Business
                          </Col>
                          <Col lg={7} sm={7} xs={7}>
                            <div className="d-flex align-items-center">
                              <span className="seprator"> : </span>
                              <span>${totalBusiness}</span>
                            </div>
                          </Col>
                        </Row>
                      </h4>
                      <h4>
                        <Row>
                          <Col lg={5} sm={5} xs={5}>
                            {" "}
                            Remaining
                          </Col>
                          <Col lg={7} sm={7} xs={7}>
                            <div className="d-flex align-items-center">
                              <span className="seprator"> : </span>
                              <span>${remaining_balance}</span>
                            </div>
                          </Col>
                        </Row>
                      </h4>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={7} md={12} className=" ">
                <div className="mr-referral-carditem mr-referral-item buyform mb-4 card-view1 ">
                  <div className="mr-contant-block d-flex align-items-center justify-content-center">
                    <img src="assets/images/mlm_tree.png" style={{ width: 200, height: 200 }} />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="latest_transaction mb-5">
          <Container>
            {loading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" role="status" variant="primary">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <DataTable
                columns={columns}
                data={businessExpiryList}
                customStyles={customStyles}
                pagination
              />
            )}
          </Container>
        </div>
      </div>
    </>
  );
};

export default BusinessExpiry;
