import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config/config";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import {
  DepositWithdrwalHistory,
  walletStatisticsAction,
  adressUpdateAction,
  handleVestingWalletDataHistory,
  UserProfile,
} from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";
import Passbook from "./Passbook/Passbook";
import { Switch } from "@mui/material";
import { FormControlLabel } from "@material-ui/core";
import Swal from "sweetalert2";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { setStepwalletReducers } from "../redux/slicess/StepwalletSlice";
import WalletModal from "./Modals/walletSelectModal";
import { Spinner } from "react-bootstrap";

import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ContentLoader from "react-content-loader";

const Wallethistory = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loginData = !Cookies.get("loginSuccessStepMrMint", {
    domain: "mrmint.io",
  })
    ? []
    : JSON.parse(
      Cookies.get("loginSuccessStepMrMint", { domain: "mrmint.io" })
    );
  const [wallethistorydata, setwallethistorydata] = useState({});
  const [activeTab, setActiveTab] = useState("withdrawrequest");
  const [viewbalance, setviewbalance] = useState(false);
  const [withdrawType, setWithdrawType] = useState(3);
  const [wallethistorydatalist, setwallethistorydatalist] = useState([]);
  const [refWallethistoryDatalist, setRefWallethistoryDatalist] = useState([]);
  const [vestingWalletHistoryDataList, setVestingWalletHistoryDataList] =
    useState([]);
  const [page, setPage] = useState(1);
  const stepWalletData = useSelector(
    (state) => state.Stepwallet.stepWalletData
  ); //this is for redux dont remove this line
  const [paginationStatus, setpaginationStatus] = useState(false);
  const [userData, setUserData] = useState(null);
  const [activeTabing, setActiveTabing] = useState(3);
  const [loding, setloding] = useState(false);

  const dispatch = useDispatch(); //this is for redux dont remove this line

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  // Handle view balance click
  const handleViewBalance = () => {
    setviewbalance(!viewbalance);
  };

  useEffect(() => {
    wallethistory();
    wallethistorylist();
    getUserProfile();
  }, [withdrawType, page]);

  const wallethistory = async () => {
    let res2 = await walletStatisticsAction();

    if (res2.success) {
      setwallethistorydata(res2.data);
    } else {
      setwallethistorydata([]);
    }
  };

  const wallethistorylist = async () => {
    setloding(true);
    try {
      let data = {
        page: page,
        limit: 10,
        withdraw_type: withdrawType,
      };
      let res = await DepositWithdrwalHistory(data);
      if (res.success) {
        setloding(false);
        if (res.data.history.length == 0) {
          setpaginationStatus(true);
        }
        if (withdrawType == 3) {
          setwallethistorydatalist((prevData) => [
            ...prevData,
            ...res.data.history,
          ]);
        } else {
          setRefWallethistoryDatalist((prevData) => [
            ...prevData,
            ...res.data.history,
          ]);
        }
      } else {
        setloding(false);
        setwallethistorydatalist([]);
      }
    } catch (error) {
      setloding(false);
      console.log(error);
    }
  };

  // --------------------------------------------------------Email verification Modal code-----------------------------------------

  const handleEmailVerificationModal = async (e) => {
    // setshowModal(true);
    Swal.fire({
      title: `Email Verification Required`,
      html: "Please verify your email address to continue. <br/> <b>Important</b>: Once your email is verified, it cannot be changed later. <br/> Thank you for your cooperation!",
      confirmButtonColor: "#3085d6",
      showCancelButton: true,
      cancelButtonColor: "#d33",
      confirmButtonText: "Verify!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        navigate(`${config.baseUrl}profile`);
      }
    });
  };
  const handleWalleteVerificationModal = async (e) => {
    // e.preventDefault();
    const result = await Swal.fire({
      text: "⚠️ Warning: This is your final wallet address. Once saved, it cannot be changed. Please ensure it's correct before continuing.",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (!result.isConfirmed) {
      return;
    }
    connectMetasmaskForAddress();
  };

  const handleWithdrawlAction = (e) => {
    if (userData) {
      if (userData.is_email_verify == 0) {
        handleEmailVerificationModal(e);
        return;
      }

      if (userData.bnb_address == null) {
        handleWalleteVerificationModal(e);
        return;
      }

      if (userData.country_name === null) {
        alert("Please select country first from mobile app!");
        return;
      }
      handleShow();
    }
  };

  const handleReferralWithdrawlAction = (e) => {
    navigate(`${config.baseUrl}referralwallet`);
  };
  const handleStakingAction = (e) => {
    navigate(`${config.baseUrl}staking`);
  };
  const handleBusinessWalletTransferAction = (e) => {
    navigate(`${config.baseUrl}businesswallettransfer`);
  };

  const handleWalletWithdrawl = (e) => {
    navigate(`${config.baseUrl}withdraw`);
  };

  const connectMetasmaskForAddress = async (e) => {
    try {
      if (window.ethereum) {
        var web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(accounts, "///", window.ethereum);
        let chainId = await web3.currentProvider.chainId;
        if (!chainId) {
          chainId = web3.currentProvider.getChainId();
        }
        if (
          chainId != config.chainId &&
          web3.utils.toHex(parseInt(chainId)) != config.chainId
        ) {
          toast.error(`Please select BNB smartchain!!`);

          return false;
        } else {
          console.log(accounts[0]);
          if (accounts[0]) {
            let publicAddress = accounts[0];
            const { signature } = await new Promise((resolve, reject) =>
              web3.eth.personal.sign(
                web3.utils.fromUtf8(`Login Mrmint`),
                publicAddress,
                (err, signature) => {
                  if (err) return reject(err);
                  return resolve({ publicAddress, signature });
                }
              )
            );
            let res = await adressUpdateAction({
              bnb_address: accounts[0],
            });
            console.log("test3");
            if (res.success) {
              loginData.bnb_address = accounts[0];
              Cookies.set("loginSuccessStepMrMint", JSON.stringify(loginData));
              window.location.href = `${config.baseUrl}wallethistory`;
              toast.success(res.msg);
            } else {
              toast.error(res.msg);
            }
          }
        }
      } else {
        toast.error("Please use Dapp browser!!");
      }
    } catch (error) {
      console.log("error", error);

      switch (error.code) {
        case 4001: // User rejected the request
          toast.error("User rejected the request.");
          break;
        case -32002: // Request already in progress
          toast.error(
            "Request already in progress. Please check your MetaMask wallet."
          );
          break;
        case "ERR_NETWORK": // Network issues
          toast.error("Network error. Please try again.");
          break;
        default:
          toast.error("An unexpected error occurred.");
          break;
      }
    }
  };

  // api for vesting wallet data ====================
  const handleVestingWalletData = async () => {
    setActiveTabing(1);
    setWithdrawType("");
    try {
      let data = {
        page: 1,
        limit: 10,
      };
      let res = await handleVestingWalletDataHistory(data);
      if (res.success) {
        setVestingWalletHistoryDataList(res.data.history);
      } else {
        setVestingWalletHistoryDataList([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setUserData(res.data);
    } else {
      setUserData([]);
    }
  };
  const handleLoadMore = () => {
    setPage(page + 1);
  };

  // this code for handle tab change

  const handleTabChange = (id) => {
    if (activeTabing !== id) {
      // Prevents unnecessary state updates on double click
      setActiveTabing(id);
      setWithdrawType(id);
      setwallethistorydatalist([]);
      setRefWallethistoryDatalist([]);
      setpaginationStatus(false);
      setPage(1);
    }
  };

  const handleVestingWithdrawlAction = () => {
    navigate(`${config.baseUrl}vestingWallateHistory`);
  };
  // dynamic tabing  section data

  const ShimmerLoader = () => (
    <Row className="gy-3">
      {Array(3)
        .fill(0)
        .map((_, index) => (
          <Col xl={4} lg={6} key={index}>
            <ContentLoader
              speed={1.5}
              width="100%"
              height={180}
              viewBox="100 0 400 180"
              backgroundColor="#2a2a2a"
              foregroundColor="#3d3d3d"
            >
              <rect x="0" y="0" rx="10" ry="10" width="100%" height="100%" />
            </ContentLoader>
          </Col>
        ))}
    </Row>
  );

  const TabContent = ({ eventKey, data, type }) => {
    return (
      <Tab.Pane eventKey={eventKey}>
        <Row className="gy-3">
          {loding ? (
            <div
              className="text-center w-100 d-flex justify-content-center align-items-center"
              style={{ height: "80vh" }}
            >
              <ShimmerLoader />
            </div>
          ) : data.length > 0 ? (
            <>
              {type === "vesting" ? (
                <>
                  {data.map((item, index) => (
                    <Col xl={4} lg={6} key={index}>
                      <div
                        style={{
                          backgroundColor: "#151515",
                          borderRadius: 10,
                          textTransform: "capitalize",
                        }}
                      >
                        <div
                          className="wallet-card-div text-white px-3 py-2 fs14"
                          style={{ borderRadius: "10px 10px 0px 0px" }}
                        >
                          Transfer
                        </div>
                        <Row className="gy-2 px-3 pt-3">
                          <Col lg={4} md={4} sm={4} xs={4}>
                            <p className="text-secondary wcard-title mb-2">
                              Date
                            </p>
                            <p className="wcard-data">{item.transfer_date}</p>
                          </Col>

                          <Col
                            lg={4}
                            md={4}
                            sm={4}
                            xs={4}
                            className="text-center"
                          >
                            <p className="text-secondary wcard-title mb-2">
                              Amount
                            </p>
                            <p className="wcard-data">{item.amount}</p>
                          </Col>
                          <Col lg={4} md={4} sm={4} xs={4} className="text-end">
                            <p className="text-secondary wcard-title mb-2">
                              Status
                            </p>
                            <p className="text-success wcard-data">Success</p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </>
              ) : (
                <>
                  {data.map((item, index) => (
                    <Col xl={12} key={index}>
                      <div
                        style={{
                          backgroundColor: "#151515",
                          borderRadius: 10,
                          textTransform: "capitalize",
                        }}
                      >
                        <div
                          className="wallet-card-div text-secondary px-3 py-2 fs14"
                          style={{ borderRadius: "10px 10px 0px 0px" }}
                        >
                          Type:{" "}
                          <span className="text-white">
                            {item.type || type}
                          </span>
                        </div>
                        <Row className="gy-2 px-3 pt-3">
                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              Requested Date
                            </p>
                            <p className="wcard-data">
                              {item.requested_date || item.requestedDate}
                            </p>
                          </Col>

                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              Status
                            </p>
                            <p
                              className={` ${item.status === "Pending"
                                ? "text-warning"
                                : item.status === "Rejected"
                                  ? "text-danger"
                                  : "text-success"
                                } wcard-data`}
                            >
                              {item.status}
                            </p>
                          </Col>

                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              {item.status === "Rejected"
                                ? "Rejected Date"
                                : "Approved Date"}
                            </p>
                            <p className="wcard-data">{item.approved_date}</p>
                          </Col>

                          {/* </Row>
                        <Row className="gy-2 px-3"> */}
                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              Requested Time
                            </p>
                            <p className="wcard-data">
                              {item.requested_time || item.requestedTime}
                            </p>
                          </Col>
                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              {item.status === "Rejected"
                                ? "Rejected Time"
                                : "Approved Time"}
                            </p>
                            <p className="wcard-data">{item.approved_time}</p>
                          </Col>
                          <Col className="text-center">
                            <p className="text-secondary wcard-title mb-2">
                              Amount
                            </p>
                            <p
                              style={{ fontWeight: 700 }}
                              className="wcard-data"
                            >
                              {item.usd_amount || item.amount} MNT
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                </>
              )}
            </>
          ) : (
            <div
              className="text-center d-flex justify-content-center align-items-center flex-column"
              style={{
                padding: "20px",
                color: "#d3d3d3",
                borderRadius: "8px",
                height: "80vh",
              }}
            >
              <img
                style={{ width: "auto", height: "auto" }}
                src="assets/images/nodata2.png"
                alt="No Data"
              />
              <h4>No data found</h4>
            </div>
          )}
        </Row>
      </Tab.Pane>
    );
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" alt="" />
        <img src="assets/images/shape1.svg" className="shape1" alt="" />
        <img src="assets/images/shape2.svg" className="shape2" alt="" />
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          <div className="mb-4">
            <Container className="">
              <Row className="align-items-center">
                <Col lg={9}>
                  <h2 className="text-white" style={{ fontWeight: 600 }}>
                    Wallet Overview
                  </h2>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ---------- */}

          <div className="mb-5 ">
            <Container className="">
              <Row>
                <Col lg={5} md={12} className="mb-3">
                  <div className="wallet_card">
                    <div className="card-view3 p-4 ">
                      <div>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h4 className="text-light-grey mb-3">
                              Total Balance
                            </h4>
                          </div>
                          <div className="mb-3">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={viewbalance}
                                  onChange={handleViewBalance}
                                />
                              }
                              label={
                                <p
                                  className="mb-0"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                  style={{ fontSize: 10, width: 30 }}
                                >
                                  {viewbalance ? "Hide" : "Show"}
                                </p>
                              }
                              style={{ color: "white" }}
                            />
                          </div>
                        </div>

                        <>
                          <span className="">
                            <img src="assets/images/mntcoin.png" width="20px" />
                            &nbsp;
                            {viewbalance
                              ? wallethistorydata.total_balance > 0
                                ? wallethistorydata.total_balance
                                : 0
                              : "********"}{" "}
                            MNT
                          </span>
                        </>
                        <div className="w-btn-list justify-content-between gap-2 flex-wrap mt-4  h-100">
                          <div>
                            <button
                              className="w-btn card-view w-100"
                              onClick={handleWithdrawlAction}
                            >
                              <div className="">
                                {/* <span className=""> */}
                                {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="22"
                                    height="13"
                                    viewBox="0 0 23 18"
                                    fill="none"
                                  >
                                    <path
                                      d="M21.3818 5.5H1.38184M1.38184 9.5H4.92851C5.4687 9.5 5.7388 9.5 5.99997 9.54655C6.23179 9.58788 6.45794 9.65635 6.67374 9.75056C6.91688 9.85669 7.14161 10.0065 7.59107 10.3062L8.1726 10.6938C8.62206 10.9935 8.8468 11.1433 9.08993 11.2494C9.30574 11.3437 9.53188 11.4121 9.7637 11.4534C10.0249 11.5 10.295 11.5 10.8352 11.5H11.9285C12.4687 11.5 12.7388 11.5 13 11.4534C13.2318 11.4121 13.4579 11.3437 13.6737 11.2494C13.9169 11.1433 14.1416 10.9935 14.5911 10.6938L15.1726 10.3062C15.6221 10.0065 15.8468 9.85669 16.0899 9.75056C16.3057 9.65635 16.5319 9.58788 16.7637 9.54655C17.0249 9.5 17.295 9.5 17.8352 9.5H21.3818M1.38184 4.2L1.38184 13.8C1.38184 14.9201 1.38184 15.4802 1.59982 15.908C1.79157 16.2843 2.09753 16.5903 2.47385 16.782C2.90168 17 3.46173 17 4.58184 17L18.1818 17C19.3019 17 19.862 17 20.2898 16.782C20.6661 16.5903 20.9721 16.2843 21.1639 15.908C21.3818 15.4802 21.3818 14.9201 21.3818 13.8V4.2C21.3818 3.0799 21.3818 2.51984 21.1639 2.09202C20.9721 1.7157 20.6661 1.40974 20.2898 1.21799C19.862 1 19.3019 1 18.1818 1L4.58184 1C3.46173 1 2.90168 1 2.47386 1.21799C2.09753 1.40973 1.79157 1.71569 1.59982 2.09202C1.38184 2.51984 1.38184 3.07989 1.38184 4.2Z"
                                      stroke="#F4F4F4"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg> */}
                                {/* <img
                                    src="assets/images/walletback2.svg"
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                      opacity: 0.5,
                                    }}
                                    alt=""
                                  /> */}

                                {/* </span> */}
                                <span className="me-1">
                                  <img
                                    src="assets/images/walletback1.svg"
                                    style={{
                                      position: "absolute",
                                      right: 0,
                                      opacity: 0.5,
                                      top: 0,
                                      zIndex: 0,
                                    }}
                                    alt=""
                                  />
                                  <img
                                    src="assets/images/walletback2.svg"
                                    style={{
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                      opacity: 0.5,
                                    }}
                                    alt=""
                                  />
                                  <img
                                    src="assets/images/wallet02.png"
                                    alt=""
                                    style={{ width: 35, height: 35 }}
                                  />
                                </span>
                                &nbsp;&nbsp;<small>Wallets</small>
                              </div>
                            </button>
                          </div>
                          <div></div>
                        </div>
                      </div>

                      <div className="chart_icon" style={{ zIndex: -1 }}>
                        <img src="assets/images/chart_wave.png" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={1} className="mb-3"></Col>
                <Col
                  lg={6}
                  className="mb-3"
                  style={{
                    fontSize: "14px",
                    marginTop: "2.5px",
                    whiteSpace: "pre-line",
                  }}
                >
                  <h3 style={{ color: "white" }}>Terms and Condition:</h3>
                  <p> {wallethistorydata.termsCondition}</p>
                </Col>

                {/* {wallethistorydata.mainBalance <= 0 ||
                  !wallethistorydata.mainBalance ? (
                  <Col lg={4} className="mb-3"></Col>
                ) : (
                  <Col lg={4} className="mb-3">
                    <div className="wallet_card">
                      <div className="card-view3 p-3">
                        <div className="">
                          <h4 className="text-light-grey mb-3">Main Wallet</h4>
                          <div className="fs-5">
                            <img
                              src="assets/images/mntcoin.png"
                              width="25px"
                              alt=""
                            />
                            &nbsp; {wallethistorydata.mainBalance} MNT
                          </div>
                        </div>

                        <div className="chart_icon">
                          <img src="assets/images/chart_wave.png" />
                        </div>
                      </div>
                    </div>
                  </Col>
                )} */}
              </Row>
            </Container>
          </div>

          {/* ---------- */}

          <div className="transaction mb-5">
            <Container>
              <h2 className="text-white mb-4" style={{ fontWeight: 600 }}>
                Statistics
              </h2>
              <div className="transaction_detail">
                <Row>
                  <Col className="mb-4 d-flex">
                    <div className="listbox flex-fill d-flex align-items-center justify-content-center w-100 ">
                      <div className="d-flex flex-column gap-2">
                        <p
                          style={{ fontWeight: 700, fontSize: 20 }}
                          className="text-white text-center mb-0"
                        >
                          {wallethistorydata.stepReward}
                          {/* <small style={{ fontSize: 10 }}>MNT</small> */}
                        </p>
                        <p className="mb-0 text-center fs14">Step Rewards</p>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-flex ">
                    <div className="listbox flex-fill d-flex align-items-center justify-content-center w-100 ">
                      <div className="d-flex flex-column gap-2">
                        <p
                          style={{ fontWeight: 700, fontSize: 20 }}
                          className="text-white text-center mb-0"
                        >
                          {wallethistorydata.referralAmount}
                          {/* <small style={{ fontSize: 10 }}>MNT</small> */}
                        </p>
                        <p className="mb-0 text-center fs14">
                          Referral Rewards
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-flex">
                    <div className="listbox flex-fill d-flex align-items-center justify-content-center w-100 ">
                      <div className="d-flex flex-column gap-2">
                        <p
                          style={{ fontWeight: 700, fontSize: 20 }}
                          className="text-white text-center mb-0"
                        >
                          {wallethistorydata.matchingAmount}
                          {/* <small style={{ fontSize: 10 }}>MNT</small> */}
                        </p>
                        <p className="mb-0 text-center fs14">
                          Community Rewards
                        </p>
                      </div>
                    </div>
                  </Col>

                  <Col className="mb-4 d-flex r">
                    <div className="listbox flex-fill d-flex align-items-center justify-content-center w-100 ">
                      <div className="d-flex flex-column gap-2">
                        <p
                          style={{ fontWeight: 700, fontSize: 20 }}
                          className="text-white text-center mb-0"
                        >
                          {wallethistorydata.loyalty_earning}
                          {/* <small style={{ fontSize: 10 }}>MNT</small> */}
                        </p>
                        <p className="mb-0 text-center fs14">
                          Walker Loyalty Rewards
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col className="mb-4 d-flex r">
                    <div className="listbox flex-fill ">
                      <div className="d-flex flex-column gap-2">
                        <p
                          style={{ fontWeight: 700, fontSize: 20 }}
                          className="text-white text-center mb-0"
                        >
                          {wallethistorydata.total_withdraw}
                          {/* <small style={{ fontSize: 10 }}>MNT</small> */}
                        </p>
                        <p className="mb-0 text-center">Total Withdrawn</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>

          {/* ---------- */}
          <div className="latest_transaction mb-5">
            <Container>
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="stepwallet"
                onSelect={(key) => setActiveTab(key)}
              >
                <h2 className="text-white mb-4" style={{ fontWeight: 600 }}>
                  Wallet History
                </h2>
                <Nav variant="pills" className=" text-center ">
                  <Row className="w-100 text-center mb-4">
                    <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => handleTabChange(3)}
                          eventKey="stepwallet"
                          className="card-view1 customnav-link px-0"
                        >
                          StepMint Wallet
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          onClick={() => handleTabChange(4)}
                          eventKey="referralwallet"
                          className="card-view1 customnav-link px-0"
                        >
                          Business Wallet
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                    <Col lg={3} sm={6}>
                      <Nav.Item>
                        <Nav.Link
                          onClick={handleVestingWalletData}
                          eventKey="vestingwallet"
                          className="card-view1 customnav-link px-0"
                        >
                          Vesting Wallet
                        </Nav.Link>
                      </Nav.Item>
                    </Col>
                  </Row>
                </Nav>
                <Tab.Content>
                  <TabContent
                    eventKey="stepwallet"
                    data={wallethistorydatalist}
                  />
                  <TabContent
                    eventKey="referralwallet"
                    data={refWallethistoryDatalist}
                  />
                  <TabContent
                    eventKey="vestingwallet"
                    data={vestingWalletHistoryDataList}
                    type="vesting"
                  />
                  <Tab.Pane eventKey="passbook">
                    {activeTab === "passbook" && <Passbook />}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>

          {activeTabing == 3 && wallethistorydatalist.length >= 10 && !loding && (
            <>
              {!paginationStatus && (
                <center onClick={handleLoadMore}>
                  <div
                    className="gap-2 py-3 joined-user"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "black",
                      width: 180,
                      borderRadius: 8,
                    }}
                  >
                    <div className="ani-container">
                      <div className="chevron"></div>
                      <div className="chevron"></div>
                      <div className="chevron"></div>
                    </div>
                    <div style={{ fontWeight: 500 }}>Load More</div>
                  </div>
                </center>
              )}
            </>
          )}
          {activeTabing == 4 &&
            refWallethistoryDatalist.length >= 10 &&
            !loding && (
              <>
                {!paginationStatus && (
                  <center onClick={handleLoadMore}>
                    <div
                      className="gap-2 py-3 joined-user"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                        width: 180,
                        borderRadius: 8,
                      }}
                    >
                      <div className="ani-container">
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                      </div>
                      <div style={{ fontWeight: 500 }}>Load More</div>
                    </div>
                  </center>
                )}
              </>
            )}
          {activeTabing == 1 &&
            vestingWalletHistoryDataList.length >= 10 &&
            !loding && (
              <>
                {!paginationStatus && (
                  <center onClick={handleLoadMore}>
                    <div
                      className="gap-2 py-3 joined-user"
                      style={{
                        color: "white",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "black",
                        width: 180,
                        borderRadius: 8,
                      }}
                    >
                      <div className="ani-container">
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                      </div>
                      <div style={{ fontWeight: 500 }}>Load More</div>
                    </div>
                  </center>
                )}
              </>
            )}
        </div>
      </div>
      {show && (
        <WalletModal
          show={show}
          handleClose={handleClose}
          userData={userData}
          handleWalletWithdrawl={handleWalletWithdrawl}
          handleVestingWithdrawlAction={handleVestingWithdrawlAction}
          handleReferralWithdrawlAction={handleReferralWithdrawlAction}
          handleStakingAction={handleStakingAction}
          handleBusinessWalletTransferAction={
            handleBusinessWalletTransferAction
          }
        />
      )}
    </>
  );
};
export default Wallethistory;
