import React, { useEffect, useState } from "react";

import {
  Container,
  Row
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../css/home.css";
import { getcmsAction } from "../../../Action/user.action";

const PrivacyPolicy = () => {
  const [privacypolicylist, setprivacypolicylist] = useState({
    privacy_policy:""
  });
  useEffect(() => {
    getprivacypolicyfunc();
    // setcondition();
  }, []);

  const getprivacypolicyfunc = async () => {
    let res = await getcmsAction("privacypolicy");
    if (res.success) {
      setprivacypolicylist(res.data);
    } else {
    }
  };

  return (
    <>
      <div className="lighttheme bg-white overflow-hidden hero" style={{ minHeight: "100vh" }}>
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              {console.log(privacypolicylist.privacy_policy)}
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: privacypolicylist.privacy_policy,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};
export default PrivacyPolicy;
