import React, { useEffect, useState } from "react";

import {
  Container,
  Row
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../css/home.css";
import { getcmsAction } from "../../../Action/user.action";

const Riskdisclosure = () => {
  const [riskdisclosure, setriskdisclosure] = useState({
    risk_disclosure:""
  });
  useEffect(() => {
    riskdisclosureFun();
    // setcondition();
  }, []);

  const riskdisclosureFun = async () => {
    let res = await getcmsAction("riskdisclosure");
    if (res.success) {
      setriskdisclosure(res.data);
      console.log(res.data);
    } else {
    }
  };


  return (
    <>
      <div className="lighttheme overflow-hidden hero" style={{ minHeight: "100vh" }}>
        <section className="herobanner position-relative text-white overflow-hidden" style={{backgroundColor:"black"}}>
          <Container>
            <Row className="align-items-center">
              <div className="jovitClass"
                dangerouslySetInnerHTML={{
                  __html: riskdisclosure.risk_disclosure,
                }}
              />
            </Row>
          </Container>
        </section>
      </div>

      
    </>
  );
};
export default Riskdisclosure;
