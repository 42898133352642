import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  getTransferHistoryAction,
  getWalletBalanceAction,
  transferAmountAction,
} from "../Action/user.action";
import toast from "react-hot-toast";
import Dashboardheader from "../directives/dashboardheader";

const BusinessWalletTransfer = () => {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [walletBalance, setWalletBalance] = useState(0);
  const [transferHistory, setTransferHistory] = useState([]);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [paginationStatus, setpaginationStatus] = useState(false);
  const [lodingStatus, setlodingStatus] = useState(false);
  


  const [page, setpage] = useState(1)

  useEffect(() => {
    fetchWalletBalance();
    fetchTransferHistory();
  }, [page]);

  const fetchWalletBalance = async () => {
    try {
      const res = await getWalletBalanceAction(); // Call your API to get wallet balance
      if (res.success) {
        setWalletBalance(res.data.business_wallet_balance); // Set the wallet balance
        setNote(res.data);
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
    }
  };

  const fetchTransferHistory = async () => {
    try {
      const data = {
        page: page,
        limit: 10, // Adjust the limit as needed
      };
      const res = await getTransferHistoryAction(data); // Call your API to get transfer history
      if (res.data.history.length == 0) {
        setpaginationStatus(true);
      }
      if (res.success) {
        // setTransferHistory(res.data.history); // Set the transfer history
        setTransferHistory((prevData) => [
          ...prevData,
          ...res.data.history,
        ]);
      }
    } catch (error) {
      console.error("Error fetching transfer history:", error);
    }
  };

  const handleBackNavigation = () => {
    navigate(-1);
  };

  const handleTransfer = async () => {
    setlodingStatus(true)
    if (!isChecked) {
      toast.error("Please check the box to agree to the Terms and Conditions.");
      setlodingStatus(false)

      return;
    }

    if (!amount || parseFloat(amount) < 100) {
      toast.error("Please enter a valid amount (minimum 100 MNT).");
      setlodingStatus(false)

      return;
    }

    try {
      const data = {
        amount: amount,
      };
      const res = await transferAmountAction(data); // Call your API to transfer amount
      if (res.success) {
        toast.success(res.msg);
        setlodingStatus(false)

        setAmount(""); // Clear the input field
        fetchWalletBalance(); // Refresh wallet balance
        fetchTransferHistory(); // Refresh transfer history
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
      else {
        toast.error(res.msg);
        setlodingStatus(false)

      }
    } catch (error) {
      console.error("Error transferring amount:", error);
      setlodingStatus(false)

    }
  };
  const handleMaxClick = () => {
    setAmount(Math.floor(walletBalance));
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}(\{0,2})?$/.test(value)) {
      setAmount(value);
    }
  };

  const handleLoadMore = () => {
    setpage(page + 1);
  };
  return (
    <>
      <Dashboardheader />
      <Container className="mb-5 text-white mt-5">
        <div
          onClick={handleBackNavigation}
          style={{ cursor: "pointer" }}
          className="d-flex gap-2 align-items-center text-white mb-3"
        >
          <p
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="mb-0"
          >
            <IoIosArrowRoundBack size={25} />
          </p>
          Back
        </div>
        <div className="card card-view rounded-20 border-0 py-4 px-1 px-sm-4">
          <div className="card-body">
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="withdraw_form2">
                  <div className="text-center mb-2 pb-1">
                    <h3 style={{ fontWeight: 600 }}>
                      Business Wallet Transfer
                    </h3>
                    <h6 className="mb-4 text-grey">
                      Transfer amount in your vesting wallet
                    </h6>
                  </div>

                  <div className="mxbtn position-relative">
                    <div>
                      <Row>
                        <Col lg={6}></Col>
                        <Col lg={6}>
                          <div className="pull-right mntbalance mb-0">
                            Balance :&nbsp;
                            <span id="web13Balance">{walletBalance} MNT</span>
                          </div>
                        </Col>
                      </Row>
                      <TextField
                        id="outlined-password-input"
                        label="Amount"
                        type="text"
                        value={amount} // Bind the input value to the state
                        onChange={handleAmountChange} // Update amount on change
                        style={{ width: "100%" }}
                        autoComplete="off"
                        placeholder="Enter Amount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <button
                      className="pull-right text-white px-4 btn btn-main2 maxamount rounded-2"
                      type="button"
                      onClick={handleMaxClick} // Set amount to wallet balance
                    >
                      MAX
                    </button>
                  </div>

                  <div className="d-flex gap-2 mt-3">
                    <div style={{ width: 15 }}>
                      <input
                        className="mt-1 custom-checkbox"
                        style={{ width: 15, height: 15 }}
                        type="checkbox"
                        name="checkbox"
                        onChange={() => setIsChecked(!isChecked)} // Toggle checkbox state

                        id="flexCheckDefault"
                      />
                    </div>
                    <div className="d-flex gap-1">
                      <p style={{ marginTop: "1px" }} className="mb-0">
                        I Agree with the
                      </p>
                      <div className="tnc">Terms and Conditions</div>
                    </div>
                  </div>

                  <Button
                    variant="main2"
                    className="w-100 mb-3 mt-3"
                    disabled={lodingStatus}
                    onClick={handleTransfer} // Call transfer function on click
                  >
                    Transfer
                  </Button>
                  {note.heading}
                  {note.content}
                </div>
                <br />
                {/* <div>
                  <h3>Terms & Conditions</h3>

                  <ul
                    className="test d-flex flex-column gap-2"
                    style={{ listStyle: "square" }}
                  >
                    <li>Withdrawals are limited to once every 168 hours.</li>
                    <li>
                      The minimum withdrawal amount is 100 MNT, and the maximum
                      withdrawal amount is 1500 MNT, which can be processed once
                      within the 168-hour tenure.
                    </li>
                    <li>
                      The 168-hour tenure begins from the time the withdrawal
                      request is submitted.
                    </li>
                    <li>
                      Withdrawal requests may take between 24 to 48 hours to get
                      approved.
                    </li>
                  </ul>
                </div> */}
              </Col>
              <Col lg={6} className="mt-3">
                <div>
                  <img src="assets/images/withdraw.png" width="100%" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <div>
          <h3>Business Wallet Transfer History</h3>
          <br />
          <Row>
            <Col xl={12}>
              {transferHistory.length > 0 ? (
                transferHistory.map((history, index) => (
                  <div
                    className="d-flex justify-content-between align-items-center p-4"
                    key={index}
                    style={{
                      backgroundColor: "#151515",
                      borderRadius: 10,
                      marginBottom: "10px",
                      padding: "10px",
                    }}
                  >
                    <p className="text-secondary mb-0 wallet-modal-data2">
                      Date: {history.transfer_date || "N/A"}
                    </p>
                    <p className="text-success mb-0 wallet-modal-data2">
                      Amount: {history.transfer_amount || "0"} MNT
                    </p>
                    <p className="text-secondary mb-0 wallet-modal-data2">
                      {history.status || "Success"}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-center text-secondary">
                  No Transfer History Available
                </p>
              )}
            </Col>
            {!paginationStatus && (
              <>
                {transferHistory.length >= 10 && (
                  <div className="text-center mt-3">
                    <Button variant="main2"
                      onClick={handleLoadMore}
                    >
                      Load More
                    </Button>
                  </div>
                )}
              </>
            )}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default BusinessWalletTransfer;
