import React, { useEffect, useState } from "react";
import config from "../config/config";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Dashboardheader from "../directives/dashboardheader";
import { TextField } from "@mui/material";
import Cookies from "js-cookie";
import {
  WithdrawalHistoryAction,
  addReffWithdrawRequestAction,
  addWithdrawRequestAction,
  addmainwalletwithdrawrequestAction,
  getStepDataAction,
  getWithdrawalTimeAction,
} from "../Action/user.action";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import CountdownTimer from "./Others/CountdownTimer";
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import toast from "react-hot-toast";

const WithdrawReferral = () => {
  const [mntAmount, setMntAmount] = useState();
  const [userDetails, setuserDetails] = useState({});
  const [validationError, setvalidationError] = useState({});
  const [withdrawHistorylist, setWithdrawHistorylist] = useState([]);
  const headingRef = React.useRef(null);
  const [balanceType, setbalanceType] = useState(1);
  const [showTooltip, setShowTooltip] = useState(false);

  const [withdrawalTimeData, setWithdrawalTimeData] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox

  const navigate = useNavigate()

  useEffect(() => {

    getReferralData()
  }, []);

  const itemsPerPage = 10; // Set the number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = ({ selected }) => {
    // alert(1);
    setTimeout(() => {
      headingRef.current.scrollIntoView();
    }, 500);

    console.log(selected, "selected123");
    setCurrentPage(selected);
  };

  const heading = () => {
    console.log(headingRef.current.scrollIntoView());

    console.log(document.getElementById("withdrawHistorylistHeading"));
  };
  const CurrentPageResetFunc = () => {
    setCurrentPage(0);
    handlePageChange({ selected: 0 });
  };
  const totalPages = Math.ceil(withdrawHistorylist.length / itemsPerPage);
  const isOnFirstPage = currentPage == 0;
  const isOnLastPage = currentPage == totalPages - 1;

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const withdrawHistorylistSliced = withdrawHistorylist.slice(
    startIndex,
    endIndex
  );


  const inputhandlerbalancetype = (e) => {
    let { name, value, id } = e.target;
    if (value == 1) {
      setbalanceType(1);
    } else {
      setbalanceType(2);
    }
  };

  const inputHandler = (e) => {
    let { name, value, id } = e.target;

    var getDecimalVal = value.toString().indexOf(".");
    var decimalPart = value.toString().substring(getDecimalVal + 1);
    if (decimalPart.length > 2 && getDecimalVal != "-1") {
    } else {
      if (/^\d{0,7}(\{0,2})?$/.test(value)) {
        setMntAmount(value);
      }
    }
  };

  function validate() {
    let withdrawAmountError = "";

    if (
      parseFloat(mntAmount).toFixed(2) === "" ||
      parseFloat(mntAmount).toFixed(2) == "0.00"
    ) {
      withdrawAmountError = "Amount is required.";
    } else if (!isNaN(parseFloat(mntAmount).toFixed(2)) === false) {
      withdrawAmountError = "Amount is not valid please enter valid amount.";
    } else if (
      parseFloat(parseFloat(mntAmount).toFixed(2)) <
      parseFloat(
        parseFloat(userDetails?.mining_reward_min__mnt_withdraw).toFixed(2)
      )
    ) {
      withdrawAmountError = `Amount should be greater than or equal to ${parseFloat(
        userDetails?.mining_reward_min__mnt_withdraw
      ).toFixed(2)} MNT`;
    } else if (userDetails.mnt_withdraw_permitted != 1) {
      withdrawAmountError =
        "Withdraw Service Temporarily Unavailable. Please try again later.";
    }
    if (balanceType == 1) {
      if (
        parseFloat(parseFloat(mntAmount).toFixed(2)) >
        parseFloat(parseFloat(userDetails?.step_balance).toFixed(2))
      ) {
        withdrawAmountError = "Insufficient funds in your Stepmint Wallet.";
      }
    }
    if (balanceType == 2) {
      if (
        parseFloat(parseFloat(mntAmount).toFixed(2)) >
        parseFloat(parseFloat(userDetails?.main_balance).toFixed(2))
      ) {
        withdrawAmountError = "Insufficient funds in your Main Wallet.";
      }
    }

    if (withdrawAmountError) {
      setvalidationError({
        withdrawAmountError,
      });
      return false;
    } else {
      return true;
    }
  }

  const withdrawFunc = async () => {
    if (!isChecked) {
      toast.error("Please check the box to agree to the Terms and Conditions.");
      return;
    }
    if (parseFloat(mntAmount) > parseFloat(userDetails.wallet_balance)) {
      toast.error("Amount exceeds wallet balance");
      return;
    }

    if (parseFloat(mntAmount) < userDetails.min_withdrawal_limit) {
      toast.error(`Minimum withdrawal limit is ${userDetails.min_withdrawal_limit}`);
      return;
    }

    if (parseFloat(mntAmount) > userDetails.max_withdrawal_limit) {
      toast.error(`Maximum withdrawal limit is ${userDetails.max_withdrawal_limit}`);
      return;
    }
    const isValid = validate();
    if (isValid) {
      setvalidationError({});
      Swal.fire({
        title: `Amount Requested : ${parseFloat(
          parseFloat(mntAmount).toFixed(2)
        )} MNT`,
        text: `Are you ready to Withdraw the Amount of Business Wallet ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Withdraw it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let res;
          if (balanceType == 1) {
            res = await addReffWithdrawRequestAction({
              usd_amount: parseFloat(parseFloat(mntAmount).toFixed(2)),
              unhold: true,
            });
          }
          if (res.success) {
            Swal.fire("Amount Withdrawn!", res.msg, "success");
            setTimeout(() => {
              window.location.href = `${config.baseUrl}wallethistory`;
            }, 1500);
          } else {
            Swal.fire("Failed to Withdraw!", res.msg, "error");
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
  };

  const maxToken = async () => {
    if (balanceType == 1) {
      console.log(userDetails)
      if (userDetails.wallet_balance > userDetails.max_withdrawal_limit) {
        setMntAmount(userDetails.max_withdrawal_limit);
      } else {
        setMntAmount(userDetails.wallet_balance);
      }
    } else {
      setMntAmount(userDetails.main_balance);
    }
  };

  const loginData = !Cookies.get("loginSuccessStepMrMint")
    ? []
    : JSON.parse(Cookies.get("loginSuccessStepMrMint"));


  const getReferralData = async () => {
    try {
      let res = await getStepDataAction('4')
      console.log(res.data);
      setuserDetails(res.data);

    } catch (error) {
      console.log(error);

    }
  }

  const handleBackNavigation = () => {
    navigate(-1)
  }
  return (
    <>
      <Dashboardheader />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">
          {/* ---------- */}
          <div className=" mb-5">
            <Container className="">
              <p style={{ color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={handleBackNavigation}>
                <IoIosArrowRoundBack size={35} />
              </p>
              <div className=" card card-view rounded-20 border-0">
                <div className="card-body">
                  <Row className="align-items-center">
                    <Col lg={6} className="">
                      <div className="withdraw_form">
                        <div className="text-center mb-2 pb-1">
                          <h3 style={{ fontWeight: 600 }}>Business Wallet Withdraw </h3>
                          <h6 className="mb-4 text-grey">
                            Get your balance in your registered Wallet
                          </h6>
                        </div>
                        <Col lg={12} className="mb-4">
                          <TextField
                            id="outlined-helperText"
                            label="Withdraw Address"
                            type="text"
                            style={{ width: "100%" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            placeholder="0x0E276DF710AD6dD91A3"
                            autoComplete="off"
                            value={loginData?.bnb_address}
                          />
                        </Col>

                        <div className="mxbtn position-relative">
                          <div>
                            <Row>
                              <Col lg={6}></Col>
                              <Col lg={6}>
                                <div class="pull-right mntbalance mb-0">
                                  Balance :&nbsp;
                                  <span id="web13Balance">
                                    {userDetails.wallet_balance} {" "}
                                    MNT
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <TextField
                              id="outlined-password-input"
                              label="Amount"
                              type="text"
                              style={{ width: "100%" }}
                              autoComplete="off"
                              disabled={userDetails.show_timer}
                              placeholder="Enter Amount"
                              value={mntAmount}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{
                                "& .MuiInputBase-input::placeholder": {
                                  color: userDetails.show_timer ? "white" : "inherit",
                                  opacity: 1,
                                },
                                "& .Mui-disabled": {
                                  color: "white",
                                },
                              }}
                              onKeyPress={(event) => {
                                if (!/^\d*[.]?\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onPaste={handleChange}
                              onChange={inputHandler}
                            />
                          </div>

                          <button
                            disabled={userDetails.show_timer}

                            onClick={maxToken}
                            className="pull-right text-white px-4 btn btn-main2 maxamount rounded-2"
                            type="button"
                          >
                            MAX
                          </button>
                        </div>

                        {userDetails?.show_timer && (
                          <div className="mt-2 text-end">
                            <CountdownTimer
                              timeDuration={userDetails?.time_duration}
                              is_withdraw_allow={userDetails?.is_withdraw_allow}
                            />
                          </div>
                        )}

                        {/* <br/> */}
                        {validationError.withdrawAmountError && (
                          <div style={{ marginTop: 8 }}>
                            <span
                              className="validationErr "
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginBottom: 5,
                                fontSize: 14,
                              }}
                            >
                              {validationError.withdrawAmountError ? (
                                validationError.withdrawAmountError
                              ) : (
                                <span style={{ visibility: "hidden" }}> .</span>
                              )}
                            </span>
                          </div>
                        )}

                        <div className="mb-4 mt-2">
                          <h6 className="mb-1">
                            <small className="text-light-grey">
                              Withdraw Fee 4%{" "}
                            </small>
                          </h6>
                          <h6 className="mb-1">
                            <small className="text-light-grey">
                              Burning Fee 1%{" "}
                            </small>
                          </h6>
                          <h6>
                            <small className="text-light-grey">
                              You will get :{" "}
                              {isNaN(
                                parseFloat(mntAmount) -
                                parseFloat(mntAmount) *
                                (parseFloat(
                                  userDetails.setting_withdraw_fee
                                ).toFixed(2) /
                                  100)
                              )
                                ? 0
                                : parseFloat(
                                  parseFloat(mntAmount) -
                                  parseFloat(mntAmount) *
                                  (parseFloat(
                                    userDetails.setting_withdraw_fee
                                  ).toFixed(2) /
                                    100)
                                ).toFixed(2)}
                              &nbsp;MNT
                            </small>
                          </h6>
                        </div>
                        <div className="d-flex gap-2 mt-3">
                          <div style={{ width: 15 }}>
                            <input
                              class="custom-checkbox mt-1"
                              style={{ width: 15, height: 15 }}
                              type="checkbox"
                              onChange={() => setIsChecked(!isChecked)} // Toggle checkbox state

                              //   checked={termsAndConditionsCheck}
                              //   onChange={inputHandler}
                              name="checkbox"
                              id="flexCheckDefault"
                            />
                          </div>
                          <div className="d-flex gap-1">
                            <p style={{ marginTop: "1px" }} className="mb-0">
                              I Agree with the
                            </p>
                            <Link
                              target="blank"
                              className=" tnc"
                              to={`${config.baseUrl}signuptermsandconditions`}
                            >
                              Terms & Conditions{" "}
                            </Link>
                          </div>
                        </div>
                        {console.log(
                          isNaN(
                            parseFloat(mntAmount) -
                            parseFloat(mntAmount) *
                            (parseFloat(
                              userDetails.mnt_withdrawal_fee_percent
                            ).toFixed(2) /
                              100)
                          )
                        )}

                        {/* <Button
                          variant="main2"
                          className="w-100 mb-3"
                          onClick={withdrawFunc}
                          disabled={userDetails?.show_timer}
                        >
                          Withdraw
                        </Button> */}
                        <div className="w-100 tooltip-container mt-2"
                          onMouseEnter={() => setShowTooltip(true)}
                          onMouseLeave={() => setShowTooltip(false)}>
                          <Button
                            variant="main2"
                            className="w-100 mb-3"
                            onClick={withdrawFunc}
                            disabled={userDetails?.show_timer || !userDetails?.is_withdraw_allow}
                          >
                            Withdraw
                          </Button>
                          {/* {showTooltip && !userDetails?.is_withdraw_allow && (
                            <div className="tooltip">{userDetails?.message}</div>
                          )} */}
                        </div>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "2.5px",
                          }}
                        >
                          {userDetails?.note}
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            marginTop: "2.5px",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {userDetails?.withdrawal_limit_msg}
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} className="mt-3">
                      <div>
                        <img
                          src="assets/images/withdraw.png"
                          width="100%"
                          alt=""
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
          {/* ---------- */}

          {/* ---------- */}
        </div>
      </div>
    </>
  );
};
export default WithdrawReferral;
