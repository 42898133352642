import React, { useEffect, useState } from "react";
import {
  Container,
  Row
} from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import "../css/home.css";
import { getcmsAction } from "../../../Action/user.action";

const SignupTermsCondition = () => {
  const [freesignupTerms, setFreeSignupterms] = useState({
    freesignup_terms:""
  });
  useEffect(() => {
    freeSignupterms();
  }, []);

  const freeSignupterms = async () => {
    let res = await getcmsAction("signupterms");
    if (res.success) {
      setFreeSignupterms(res.data);
      console.log(res.data);
    } else {
    }
  };

  return (
    <>
     <div className="lighttheme overflow-hidden hero" style={{ minHeight: "100vh" }}>
  <section className="herobanner position-relative text-white overflow-hidden" style={{ backgroundColor: "black", minHeight: "100vh" }}>
    <Container>
      <Row className="align-items-center">
        <div className="jovitClass"
          dangerouslySetInnerHTML={{
            __html: freesignupTerms.signuptermsandcondition,
          }}
        />
      </Row>
    </Container>
  </section>
</div>


      
    </>
  );
};
export default SignupTermsCondition;
