import React, { useContext, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast"
import Dashboardheader from "../directives/dashboardheader"
import { Col, Container, Row } from "react-bootstrap";
import { getVestingWallateHistoryAction } from "../Action/user.action";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const VestingWallateHistory = () => {
    const [vestingWallateHistory, setVestingWallateHistory] = useState([])
    const navigate = useNavigate()
    const [paginationStatus, setpaginationStatus] = useState(false)
    const [page, setPage] = useState(1);

    useEffect(() => {
        getVestingWallateHistory()
    }, [page])

    const getVestingWallateHistory = async () => {
        const body = {
            "page": page,
            "limit": 10
        }
        let res = await getVestingWallateHistoryAction(body);
        if (res.data.history.length == 0) {
            setpaginationStatus(true);
        }
        if (res.success) {
            setVestingWallateHistory(prevData => [...prevData, ...res.data.history]);
        } else {
            setVestingWallateHistory([]);
        }
    };

    const handleBackNavigation = () => {
        navigate(-1)
    }
    const handleLoadMore = () => {
        setPage(page + 1);
    };
    return (
        <>
            <Dashboardheader />
            <Toaster />
            <Container>
                <Row className="my-4">
                    <h4 style={{ color: 'white' }}>Vesting Wallet Details </h4>
                    <p
                        className="position-relative"
                        style={{ color: 'white', cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                        onClick={handleBackNavigation}>
                        <IoIosArrowRoundBack size={35} />
                    </p>
                    {vestingWallateHistory.length > 0 ? <>

                        {vestingWallateHistory.map((item, index) => (
                            <Col xl={12} lg={6} className="mb-4">
                                <div
                                    style={{
                                        backgroundColor: "#151515",
                                        borderRadius: 10,
                                        textTransform: "capitalize",
                                    }}
                                >
                                    <div
                                        className="wallet-card-div text-white px-3 py-2 fs14"
                                        style={{ borderRadius: "10px 10px 0px 0px" }}
                                    >
                                        {/* Release MNT */}
                                    </div>
                                    <Row className="gy-2 px-3 pt-3">
                                        <Col lg={4} md={4} sm={4} xs={4} className="">
                                            <p className="text-secondary wcard-title mb-2">
                                                Amount
                                            </p>
                                            <p className="wcard-data">

                                                {item.amount} MNT

                                            </p>
                                        </Col>

                                        <Col
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            className="text-center"
                                        >
                                            <p className="text-secondary wcard-title mb-2">
                                                Release Date
                                            </p>
                                            <p className="wcard-data">
                                                {item.release_date}
                                            </p>
                                        </Col>
                                        <Col
                                            lg={4}
                                            md={4}
                                            sm={4}
                                            xs={4}
                                            className="text-end"
                                        >
                                            <p
                                                style={{}}
                                                className={`text-success wcard-data`}
                                            >
                                                Status
                                            </p>
                                            <p>
                                                {item.status}
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        ))
                        }
                    </>
                        : <div
                            className="text-center d-flex justify-content-center align-items-center flex-column "
                            style={{
                                padding: "20px",
                                color: "#d3d3d3",
                                borderRadius: "8px",
                                height: "80vh",
                            }}
                        >
                            <img style={{ width: "auto", height: "auto" }} src='assets/images/nodata2.png' />
                            <h4>No data found</h4>
                        </div>}

                    {vestingWallateHistory.length >= 10 && (
                        <>
                            {!paginationStatus && (
                                <center onClick={handleLoadMore}>
                                    <div
                                        className="gap-2 py-3 joined-user"
                                        style={{
                                            color: "white",
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor: "black",
                                            width: 180,
                                            borderRadius: 8,
                                        }}
                                    >
                                        <div className="ani-container">
                                            <div className="chevron"></div>
                                            <div className="chevron"></div>
                                            <div className="chevron"></div>
                                        </div>
                                        <div style={{ fontWeight: 500 }}>Load More</div>
                                    </div>
                                </center>
                            )}
                        </>
                    )}

                </Row>
            </Container>
        </>
    )
}
export default VestingWallateHistory