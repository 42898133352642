import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  getStackingDetailsListAction,
  getStackingHistoryListAction,
  submitStakingAction,
} from "../../Action/user.action";
import StakeHistory from "./stakingHistory";
import Dashboardheader from "../../directives/dashboardheader";
import toast, { ToastBar } from "react-hot-toast";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import config from "../../config/config";

const Staking = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [stakingData, setStakingData] = useState([]);
  const [vestingBal, setVestingBal] = useState("");
  const [amount, setAmount] = useState();
  const [stakingHistory, setStakingHistory] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // State for checkbox
  const [stakTerms, setStakTerms] = useState('')
  const [lodingStatus, setlodingStatus] = useState(false);

  const navigate = useNavigate();
  const handleBackNavigation = () => {
    navigate(`${config.baseUrl}wallethistory`);
  };
  useEffect(() => {
    getStackingDetailsList();
    loadMoreHistory();
  }, []);

  const handleDivClick = (index, status, planId) => {
    if (status === 1) {
      setActiveIndex(index);
      setSelectedPlanId(planId);
    }
  };

  const getStackingDetailsList = async () => {
    try {
      const res = await getStackingDetailsListAction();
      if (res.success) {
        setStakingData(res.data.staking_plans);
        setVestingBal(res.data.vesting_balance);
        setStakTerms(res.data.terms)
      }
    } catch (error) {
      console.error("Error fetching staking details:", error);
    }
  };

  const loadMoreHistory = async (page = 1) => {
    try {
      const data = {
        page: page,
        limit: 10, // Adjust the limit as needed
      };
      const res = await getStackingHistoryListAction(data); // Call your action to fetch history
      if (res.success) {
        setStakingHistory((prevHistory) => [
          ...prevHistory,
          ...res.data.history,
        ]); // Append new history to existing
      }
    } catch (error) {
      console.error("Error loading more history:", error);
    }
  };

  const StackAmount = async () => {
    setlodingStatus(true)

    if (!isChecked) {
      toast.error("Please check the box to agree to the Terms and Conditions.");
      setlodingStatus(false)

      return;
    }
    if (!selectedPlanId) {
      toast.error("Please select a staking plan.");
      setlodingStatus(false)

      return;
    }

    if (!amount) {
      toast.error("Amount cannot be empty.");
      setlodingStatus(false)

      return;
    }

    if (parseFloat(amount) < 100) {
      toast.error("The minimum staking amount is 100 MNT.");
      setlodingStatus(false)

      return;
    }
    try {
      const data = {
        amount: amount,
        staking_plan_id: selectedPlanId,
      };
      const res = await submitStakingAction(data);
      if (res.success) {
        setlodingStatus(false)

        toast.success(res.msg)
        setTimeout(()=>{
          window.location.reload();
        },1000)
      }
      else {
        toast.error(res.msg)
        setlodingStatus(false)

      }

    } catch (error) {
      console.error("Error staking amount:", error);
      setlodingStatus(false)

    }
  };

  const handleMaxClick = () => {
    setAmount(Math.floor(vestingBal));
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,6}(\{0,2})?$/.test(value)) {
      setAmount(value);
    }
  };

  return (
    <>
      <Dashboardheader />

      <Container className=" px-sm-5 px-2  mb-5 text-white  mt-5">
        {/* <div
          onClick={handleBackNavigation}
          className="d-flex gap-2  align-items-center text-white mb-4"
          style={{ cursor: "pointer" }}
        >
          <p
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="mb-0"
          >
            <IoIosArrowRoundBack size={25} />
          </p>
          Staking
        </div> */}
        <div
          onClick={handleBackNavigation}
          style={{ cursor: "pointer" }}
          className="d-flex gap-2 align-items-center text-white mb-3"
        >
          <p
            style={{
              color: "white",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
            }}
            className="mb-0"
          >
            <IoIosArrowRoundBack size={25} />
          </p>
          Back
        </div>
        <div className=" card card-view rounded-20 border-0">
          
          <div className="card-body">
            <Row className="">
              <Col lg={6} className="px-5 py-3">
                <div className="withdraw_form2">
                  <div className="text-center pb-1">
                    <h3 style={{ fontWeight: 600 }}>Vesting Balance</h3>
                  </div>
                  <div className="text-center mb-4 pb-1">
                  Balance :&nbsp;
                  <span id="web13Balance">{vestingBal} MNT</span>
                  </div>
                  <div className="mxbtn position-relative">
                    <div>
                      {/* <Row>
                        <Col lg={6}></Col>
                        <Col lg={6}>
                          <div className="pull-right mntbalance mb-0">
                            Balance :&nbsp;
                            <span id="web13Balance">{vestingBal} MNT</span>
                          </div>
                        </Col>
                      </Row> */}
                      <TextField
                        id="outlined-password-input"
                        label="Amount"
                        type="text"
                        value={amount} // Bind the input value to the state
                        onChange={handleAmountChange}
                        style={{ width: "100%" }}
                        autoComplete="off"
                        placeholder="Enter Amount"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <button
                      className="pull-right text-white px-4 btn btn-main2 maxamount rounded-2"
                      type="button"
                      onClick={handleMaxClick}
                    >
                      MAX
                    </button>
                  </div>
                  <div className="flex gap-2 py-3">
                    {stakingData?.map((data, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          handleDivClick(index, data.status, data.id)
                        }
                        className={`option ${
                          activeIndex === index
                            ? "active-vesting dash-gradient"
                            : "simple-vesting"
                        }`}
                        style={{
                          cursor: data.status === 1 ? "pointer" : "not-allowed",
                          opacity: data.status === 1 ? 1 : 0.5,
                          pointerEvents: data.status === 1 ? "auto" : "none",
                        }}
                      >
                        <p className="text-white">
                          {data.duration_month} <br />
                          Months
                        </p>
                        <p className="mb-1" style={{ fontSize: 12 }}>
                          <span
                            style={{
                              fontWeight: 600,
                              color: "white",
                              fontSize: 16,
                            }}
                          >
                            {data.apy}%
                          </span>{" "}
                          &nbsp; APR
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="d-flex gap-2  mb-3 align-items-center">
                    <div style={{ width: 15 }}>
                      <input
                        class="custom-checkbox mt-1"
                        style={{ width: 15, height: 15 }}
                        type="checkbox"
                        onChange={() => setIsChecked(!isChecked)} // Toggle checkbox state
                        //   checked={termsAndConditionsCheck}
                        //   onChange={inputHandler}
                        name="checkbox"
                        id="flexCheckDefault"
                      />
                    </div>
                    <div className="d-flex gap-1 ">
                      <p style={{ marginTop: "1px" }} className="mb-0">
                        I Agree with the
                      </p>
                      <Link
                        target="blank"
                        className=" tnc"
                          to={`${config.baseUrl}signuptermsandconditions`}
                      >
                        Terms & Conditions{" "}
                      </Link>
                    </div>
                  </div>
                  <Button
                    variant="main2"
                    className="w-100 mb-3"
                    disabled={lodingStatus}
                    onClick={StackAmount}
                  >
                    Stake Now
                  </Button>
                 
                </div>
              </Col>

              <Col lg={6} className="mt-3">
                <div className="">
                <p
                  style={{
                    fontSize: "14px",
                    marginTop: "2.5px",
                  }}
                  dangerouslySetInnerHTML={{ __html: stakTerms }}
                ></p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <StakeHistory
          stakingHistory={stakingHistory}
          loadMoreHistory={loadMoreHistory}
        />
      </Container>
    </>
  );
};

export default Staking;
