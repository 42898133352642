import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import config from "../config/config";
import Header from "../directives/header";
import { GoVerified } from "react-icons/go";
import Footer from "../directives/footer";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Spinner
} from "react-bootstrap";
import { Zoom } from "react-reveal";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Cookies from "js-cookie";
import { Base64 } from "js-base64";
import { useNavigate } from 'react-router-dom';
import SignUpEmailVerificationModal from "./Modals/SignUpEmailVerificationModal";
import { sendOtpForEmailVerificationAction, sendOtpForMobileVerificationAction, sendOtpForVerificationAction, userRegistrationActionPhase2 } from "../Action/user.action";
import { MdVerified } from "react-icons/md";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import SignupMetaTag from "./MetaTags/SignupMetaTag";
import "react-phone-input-2/lib/bootstrap.css";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import LoginSignUpHeader from "../directives/loginsingupheader";
import CongratulationModal from "./Modals/CongratulationModal";
const Signup = () => {
  // const userData = JSON.parse(localStorage.getItem('userData'))
  const userData = JSON.parse(localStorage.getItem('userData')) || {};

  const loginData = (!Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' })) ? [] : JSON.parse(Cookies.get('loginSuccessStepMrMint', { domain: 'mrmint.io' }));

  const [connectWalletAddress, setConnectWalletAddress] = useState('');
  const [form, setForm] = useState({ email: userData.email || '', mobile: userData.phone || '', password: '', firstName: '', lastName: '', confirm_password: '', referral_address: '', termscondition: false })
  const [isCaptcha, setisCaptcha] = useState(0);
  const [validationError, setvalidationError] = useState({ firstNameError: '', termsAndConditionsError:'', lastNameError: '', emailError: '', mobileError: '' });
  const [showModal, setshowModal] = useState(false)
  const [userToken, setUserToken] = useState([])
  const [verificationStatus, setVerificationStatus] = useState('email')
  const [emailstatus, setemailstatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false); // Loader state
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [showCongratulationModal, setShowCongratulationModal] = useState(false)
  const [isMobileSkip,setIsMobileSkip] = useState(false)


  let { referral_address } = useParams();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countryCode, setCountryCode] = useState('in')
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = useState(false)

  let captchaKey = config.TEST_SITE_KEY;
  let url1 = window.location.href;
  if (url1 == 'https://mrmint.biz/signup/' || url1 == 'https://mrmint.biz/signup' || url1 == 'http://mrmint.biz/signup/' || url1 == 'http://mrmint.biz/signup' || url1 == 'mrmint.biz/signup/' || url1 == 'mrmint.biz/signup/') {
    captchaKey = config.TEST_SITE_KEY_BIZ;
  }

  let url = window.location.href;
  let result = url.split('ref=');
  let Param = result[1];

  if (!referral_address && Param) {
    let decodeAddress = Base64.decode(Param);
    referral_address = decodeAddress
  } else if (!Param) {
    referral_address = referral_address;
  }



  useEffect(() => {
    if (loginData.id) {
      window.location.href = `${config.baseUrl}`
    }

    if (referral_address) {
      setForm((old) => {
        return { ...old, 'referral_address': referral_address }
      })
    }

    setTimeout(async () => {
      if (window.ethereum) {
        const { ethereum } = window;
        setConnectWalletAddress(ethereum.selectedAddress);
      }
    }, 200);

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', function (accounts) {
        setConnectWalletAddress(accounts[0]);
      })
    }

  }, []);

  const captcha = async => {
    setisCaptcha(1);
    setvalidationError((old) => {
      return { ...old, ['isCaptchaError']: '' }
    })
  }

  const connectMetasmask = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setConnectWalletAddress(accounts[0]);
      } else {
        toast.error('Please use Dapp browser!!');
      }
    } catch (error) {
      if (error.code == -32002) {
        toast.error('Request Already Processing, Please check your Meta Mask wallet');
      }
    }
  }



  const inputHandler = (e) => {
    // alert(1)
    const { name, value, id } = e.target
    if (value != '') {
      setvalidationError((old) => {
        return { ...old, [id]: '' }
      })
    }
    if (name == 'checkbox') { 
      setTermsAndConditionsCheck(!termsAndConditionsCheck)
    }

    setForm((old) => {
      return { ...old, [name]: value }
    })
  }
  const handlePhoneChange = (value, data) => {
    setForm((old) => ({
      ...old,
      countryCode: `+${data.dialCode}`,
      mobile: value.slice(data.dialCode.length)
    }));
    setCountryCode(data.dialCode);

    // Clear the mobile validation error when the user changes the phone input
    setvalidationError((old) => ({
      ...old,
      mobileError: '' // Clear mobile error
    }));
  };
  function validate() {
    let emailError = "";
    let passwordError = "";
    let confirmPasswordError = "";
    let firstNameError = "";
    let lastNameError = "";
    // let mobileError = "";
    let termsAndConditionsError="";

    if (!form.email) {
      emailError = "Email is required.";
    } else if (form.email.includes(' ')) {
      emailError = "Email cannot contain spaces.";
    } else if (!/^[\w-]+(\.[\w-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z]{2,})+$/.test(form.email)) {
      emailError = "Invalid email format. Please enter a valid email.";
    }


    // Check if first name is empty or contains spaces
    if (!form.firstName) {
      firstNameError = "First name is required.";
    } else if (form.firstName.includes(' ')) {
      firstNameError = "First name cannot contain spaces.";
    }

    // Check if last name is empty or contains spaces
    if (!form.lastName) {
      lastNameError = "Last name is required.";
    } else if (form.lastName.includes(' ')) {
      lastNameError = "Last name cannot contain spaces.";
    }

    // Check if mobile is empty or contains spaces
    // if (!form.mobile) {
    //   mobileError = "Whatsapp number is required.";
    // } else if (form.mobile.includes(' ')) {
    //   mobileError = "Whatsapp number cannot contain spaces.";
    // }

    // Validate password
    if (!form.password) {
      passwordError = "Password is required.";
    } else {
      if (form.password.includes(' ')) {
        passwordError = "Password cannot contain spaces.";
      } else if (form.password.length < 8) {
        passwordError = "Password should be at least 8 characters long.";
      } else if (!/[a-z]/.test(form.password)) {
        passwordError = "Password should contain at least 1 small letter.";
      } else if (!/[A-Z]/.test(form.password)) {
        passwordError = "Password should contain at least 1 capital letter.";
      } else if (!/[0-9]/.test(form.password)) {
        passwordError = "Password should contain at least 1 numeric character.";
      } else if (!/[\W_]/.test(form.password)) {
        passwordError = "Password should contain at least 1 special character.";
      }
    }

    // Validate confirm password
    if (!form.confirm_password) {
      confirmPasswordError = "Confirm password is required.";
    } else if (form.password !== form.confirm_password) {
      confirmPasswordError = "Password and confirm password do not match.";
    }
    if (termsAndConditionsCheck== false) {
      termsAndConditionsError = "Please read Terms Condition and Privacy Policy before moving further.";
    } 
    // Set validation errors if any
    if (emailError || firstNameError || lastNameError || 
      // mobileError ||
       passwordError || confirmPasswordError||termsAndConditionsError) {
      setvalidationError({
        emailError,
        passwordError,
        // mobileError,
        firstNameError,
        lastNameError,
        termsAndConditionsError,
        confirmPasswordError,
      });
      return false;
    } else {
      return true;
    }
  }



  const SubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid || !termsAndConditionsCheck) {
      return;
    }
    if (form.referral_address == '') {
      const result = await Swal.fire({
        text: 'Are you sure you want to register without a referral code?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });
      if (!result.isConfirmed) {
        return;
      }
    }
    handleFormSubmission();
  };

  const handleShowCongratulationPop = () => {
    setShowCongratulationModal(true)
  }

  const handleFormSubmission = async () => {
    try {
      setLoadingSubmit(true);

      const isValid = validate();
      if (!isValid) {
        setLoadingSubmit(false);
        return;
      }

      const data = {
        referralAddress: form.referral_address ? form.referral_address : referral_address ? referral_address : config.adminAddress,
        firstName: form.firstName,
        lastName: form.lastName,
        password: form.password,
        confirmPassword: form.confirm_password,
        token: userData.token,
        isMobileSkip: (userData && userData.phoneVerified) ? false : true
      };
      let res = await userRegistrationActionPhase2(data);
      console.log(res, "")
      if (res.success) {
        toast.success(res.msg);
        handleShowCongratulationPop()
        // setShowCongratulationModal(true)
        setLoadingSubmit(false);

        // localStorage.removeItem('userData');
        // setTimeout(() => {
        //   navigate(`${config.baseUrl}login`);
        // }, 2000);
      }
      if (!res.success) {
        setLoadingSubmit(false);
        toast.error(res.msg);
      }

      if (res.status === 2000) {
        setLoadingSubmit(false);

        // Clear local storage and reset only email and mobile fields
        localStorage.clear();
        setForm((prevForm) => ({
          ...prevForm,
          email: '',
          mobile: ''
        }));
        setvalidationError((prevErrors) => ({
          ...prevErrors,
          emailError: '',
          mobileError: ''
        }));
      }
    } catch (error) {
      console.log(error);
      setLoadingSubmit(false);
      toast.error("We are sorry, We're experiencing technical difficulties.");
    }
  };

  const handleOpenLogin = () => {
    localStorage.removeItem('userData');
    setTimeout(() => {
      navigate(`${config.baseUrl}login`);
    }, 300);
  }


  const handleVerifyEmail = () => {
    setVerificationStatus('email')
    if (form.email) {
      if (!validationError.emailError) {
        sendOtpForEmailVerification()
      } else {
        toast.error(validationError.emailError)
      }
    } else {
      toast.error("Please enter email")
    }
  }

  const sendOtpForEmailVerification = async () => {
    if (!/^[\w-]+(\.[\w-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z]{2,})+$/.test(form.email)) {
      setvalidationError((prevErrors) => ({
        ...prevErrors,
        emailError: "Invalid email format. Please enter a valid email."
      }));
      return;
    }
    setLoading(true);
    try {
      const data = {
        email: form.email,
      }

      let res = await sendOtpForEmailVerificationAction(data)
      if (res.success) {
        setUserToken(res.data[0].token)
        setshowModal(true)
        toast.success(
          res.msg
        );
        setTimer(60)
        setIsTimerActive(true)
        setLoading(false);
      }
      else {
        if (res.status === 2000) {
          setLoading(true);
          // Clear local storage and reset only email and mobile fields
          localStorage.clear();
          setshowModal(true)
          setForm((prevForm) => ({
            ...prevForm,
            email: '',
            mobile: ''
          }));
          setvalidationError((prevErrors) => ({
            ...prevErrors,
            emailError: '',
            mobileError: ''
          }));
        }
        toast.error(
          res.msg
        );
        setTimer(0)
        setLoading(false);

      }
    } catch (error) {
      console.log(error)
    }
  }

  // -----------------------------------------------||Section For Mobile Verification Logic||------------------------
  const handleVerifyMobile = () => {

    setVerificationStatus('mobile')
    if (form.mobile) {
      if (!validationError.mobileError) {
        sendOtpForMobileVerification()
      } else {
        toast.error(validationError.mobileError)
      }
    } else {
      toast.error("Please enter whatsapp number")
    }
  }

  const sendOtpForMobileVerification = async () => {
    setIsVerifying(true);
    try {
      const data = {
        phone: form.mobile,
        token: userData.token,
        referralAddress: form.referral_address,
        countryCode: form.countryCode
      }
      let res = await sendOtpForMobileVerificationAction(data)
      if (res.success) {
        setUserToken(res.data[0].token)
        setshowModal(true)
        toast.success(
          res.msg
        );
        setTimer(60)
        setIsTimerActive(true)
        setIsVerifying(false);
      } else {
        if (res.status === 2000) {
          setIsVerifying(true);
          setshowModal(false)

          // Clear local storage and reset only email and mobile fields
          localStorage.clear();
          setForm((prevForm) => ({
            ...prevForm,
            email: '',
            mobile: ''
          }));
          setvalidationError((prevErrors) => ({
            ...prevErrors,
            emailError: '',
            mobileError: ''
          }));
        }
        toast.error(
          res.msg
        );
        setIsVerifying(false);
        setTimer(0)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleEmailVerifyUpdate = async () => {
    // Get the 'userData' from localStorage
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData.emailVerify == true) {
      userData.emailVerify = false;

      localStorage.setItem('userData', JSON.stringify(userData));
    }
    setemailstatus(!emailstatus)
  };
  const handlePhoneVerifyUpdate = async () => {
    // Get the 'userData' from localStorage
    let userData = JSON.parse(localStorage.getItem('userData'));

    if (userData && userData.phoneVerified == true) {
      userData.phoneVerified = false;

      localStorage.setItem('userData', JSON.stringify(userData));
    }
    setemailstatus(!emailstatus)
  };

  // For Eye Button action =============================
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  return (
    <>
      <LoginSignUpHeader />
      <SignupMetaTag />
      <Toaster />

      <section className="position-relative overflow-hidden signup">
        <div className="shape_bg">
          <img src="assets/images/shape.svg" className="shape" />
          <img src="assets/images/shape1.svg" className="shape1" />
          <img src="assets/images/shape2.svg" className="shape2" />
        </div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="">
              <Zoom>
                <div className="signup_form card-view">
                  <Container>
                    <div className="login-form">
                      <div className="login-welcome">Welcome to StepMint</div>

                      {/* --------------------------------------------------------------- */}
                      <Form className="form-border" onSubmit={SubmitForm}>
                        <Form.Group className="field-set mb-3">
                          <Form.Label>Email</Form.Label>

                          <Row className="gy-3">
                            <Col lg={9}>
                              <Form.Control onChange={inputHandler} name='email'
                                type="email" placeholder="Email address"
                                id='emailError'
                                value={form.email || ''}
                                disabled={userData?.emailVerify == false || userData.emailVerify == undefined ? false : true}
                                autoComplete="off" />
                            </Col>


                            <Col lg={3} className="d-flex align-items-center">
                              {userData.emailVerify == false || userData.emailVerify == undefined ?

                                <Button
                                  variant="main2"
                                  className="text-white w-100"
                                  onClick={handleVerifyEmail}
                                  disabled={loading} // Disable button when loading
                                >
                                  {loading ? (<>
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Verifying...
                                  </>
                                  ) : (
                                    <Link
                                      className="text-light"
                                      style={{ textDecoration: "none", fontSize: 14 }}
                                    >
                                      Verify
                                    </Link>
                                  )}
                                </Button>

                                :

                                <Button
                                  variant="primary"
                                  className="text-white w-100"

                                >
                                  <Link
                                    className="text-light"
                                    style={{ textDecoration: "none", fontSize: 14 }}
                                  >
                                    Verified <GoVerified />
                                  </Link>

                                </Button>
                              }

                            </Col>
                          </Row>
                          <span className="validationError w-auto">{validationError.emailError}</span>
                          <div className="text-right">
                            {userData?.emailVerify == true &&
                              <a onClick={handleEmailVerifyUpdate} style={{ cursor: 'pointer', color: "primary" }} className=" text-right me-2 custom-a"> Change Email</a>
                            }
                          </div>
                        </Form.Group>

                        <Form.Group className="field-set mb-3">
                          {/* --------------------------------------------------------------------- */}
                          <Form.Label>Whatsapp number (optional)</Form.Label>

                          {userData?.emailVerify == true ?
                            <Row className="gy-3">
                              <Col lg={9}>
                                <PhoneInput
                                  country={"in"}
                                  enableSearch={true}
                                  disabled={userData?.phoneVerified === false || userData.phoneVerified === undefined ? false : true}
                                  className="w-100 fc"
                                  style={{ backgroundColor: "transparent !important", borderColor: "#828282 !important" }}
                                  value={`${form.countryCode || userData.countryCode}${form.mobile}`}
                                  name="mobile"
                                  onChange={handlePhoneChange}
                                  placeholder="Enter Whatsapp number"
                                />
                              </Col>

                              <Col lg={3} className="d-flex align-items-center">
                                {userData.phoneVerified === false || userData.phoneVerified === undefined ? (
                                  isVerifying ? (
                                    <Button variant="main2" className="text-white w-100" disabled>
                                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> Verifying...
                                    </Button>
                                  ) : (
                                    <Button variant="main2" className="text-white w-100" onClick={handleVerifyMobile}>
                                      <span className="text-light" style={{ textDecoration: "none", fontSize: 14 }}>Verify</span>
                                    </Button>
                                  )
                                ) : (
                                  <Button variant="primary" className="text-white w-100">
                                    <span className="text-light" style={{ textDecoration: "none", fontSize: 14 }}>
                                      Verified <GoVerified />
                                    </span>
                                  </Button>
                                )}
                              </Col>
                            </Row>

                            :
                            <>
                              <Row className="gy-3">
                                <Col lg={9}>
                                  {/* Country code dropdown */}
                                  <PhoneInput
                                    country={"in"}
                                    enableSearch={true}
                                    disabled={true}
                                    className="w-100 fc"
                                    style={{ backgroundColor: "transparent !important", borderColor: "#828282 !important" }}
                                    value={`${form.countryCode}${form.mobile}`}
                                    name='mobile'
                                    onChange={handlePhoneChange}
                                  />
                                </Col>
                                <Col lg={3} className="d-flex align-items-center">
                                  {userData.phoneVerified == false || userData.phoneVerified == undefined ?
                                    <Button variant="main2" disabled className=" text-white w-100 "
                                      onClick={handleVerifyMobile}
                                    >
                                      <Link className="text-light" style={{ textDecoration: "none", fontSize: 14 }}>Verify </Link>
                                    </Button>
                                    :
                                    <Button
                                      variant="primary"
                                      className="text-white w-100"
                                    >
                                      <Link
                                        className="text-light"
                                        style={{ textDecoration: "none", fontSize: 14 }}
                                      >
                                        Verified <GoVerified />
                                      </Link>

                                    </Button>}
                                </Col>
                              </Row>

                            </>
                          }
                          <span className="validationError">{validationError.mobileError}</span>
                          <div className="text-right">
                            {userData?.phoneVerified == true &&
                              <a onClick={handlePhoneVerifyUpdate} style={{ cursor: 'pointer', color: "primary" }} className=" text-right me-2 custom-a"> Change phone</a>
                            }
                          </div>
                          <Form.Label style={{ color: "white" }}>Note: Please make sure its your Whatsapp number</Form.Label>
                        </Form.Group>

                        {/* ---------------------------------------------------------------------------- */}
                        <Form.Group className="field-set mb-3">


                          <Row className="">
                            <Col lg={6}>
                              <Form.Label>First name</Form.Label>
                              <Form.Control
                                onChange={inputHandler}
                                name="firstName"
                                type="text"
                                placeholder="First name"
                                id="firstName"
                                autoComplete="off"
                              />
                              <span className="validationError">{validationError.firstNameError}</span>
                            </Col>

                            <Col lg={6}>
                              <Form.Label>Last name</Form.Label>
                              <Form.Control
                                onChange={inputHandler}
                                name="lastName"
                                type="text"
                                placeholder="Last name"
                                id="lastName"
                                autoComplete="off"
                              />
                              <span className="validationError">{validationError.lastNameError}</span>
                            </Col>
                          </Row>
                        </Form.Group>

                        {/* ---------------------------------------------------------------- */}


                        <Row>
                          <Col lg={6}>
                            <Form.Group className="field-set ">
                              <Form.Label>Password</Form.Label>
                              <div className="">
                                <div className="position-relative">
                                  <input
                                    type={showPassword ? "text" : "password"} // Toggle between text and password
                                    className="form-control password-input"
                                    onChange={inputHandler}
                                    autoComplete="off"
                                    placeholder="Enter your password"
                                    id="passwordError"
                                    name="password"
                                  />
                                  <div
                                    className="eye-icon2"
                                    onClick={togglePasswordVisibility} // Add onClick handler
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {showPassword ? <FaEye /> : <FaEyeSlash />} {/* Toggle icon */}
                                  </div>
                                </div>
                              </div>
                              <span className="validationError">{validationError.passwordError}</span>


                            </Form.Group>

                          </Col>

                          {/* Confirm Password Field */}

                          <Col lg={6}>
                            <Form.Label> Confirm password</Form.Label>
                            <Form.Group className="field-set ">
                              <div className="">
                                <div className="position-relative">
                                  <input
                                    type={showConfirmPassword ? "text" : "password"} // Toggle between text and password
                                    className="form-control password-input"
                                    onChange={inputHandler}
                                    autoComplete="off"
                                    placeholder="Enter confirm password"
                                    id="confirmPasswordError"
                                    name="confirm_password"
                                  />
                                  <div
                                    className="eye-icon2"
                                    onClick={toggleConfirmPasswordVisibility} // Add onClick handler
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />} {/* Toggle icon */}
                                  </div>
                                </div>
                              </div>
                              <span className="validationError">{validationError.confirmPasswordError}</span>
                            </Form.Group>
                          </Col>
                          <Form.Label className="mb-3" style={{ color: "white" }}>The password should contain 1 special character, 1 Uppercase letter, at least 8 characters, at least 1 number </Form.Label>
                        </Row>

                        <Form.Group className="field-set ">
                          <Form.Label>Referral code (optional)</Form.Label>
                          <Form.Control type="text" name="referral_address" onChange={inputHandler} value={form.referral_address} placeholder="Referral code" />
                          <span className="validationError">{validationError.walletAddressError}</span>
                        </Form.Group>

                        <div className=" d-flex mb-0  mt-2 pl-0 gap-2 align-items-baseline text-secondary"  >
                           <div style={{width:15}}>
                      <input class="form-check-input mt-1" style={{width:15, height:15}} type="checkbox"
                        checked={termsAndConditionsCheck} 
                          onChange={inputHandler} name="checkbox" id="flexCheckDefault" /> 
                          </div>
                          <p style={{marginTop:"1px"}} className="mb-0">By signing up, I Agree to StepMint <Link  target="blank"  className="text-primary"
                          to={`${config.baseUrl}signuptermsandconditions`}>Terms and Conditions </Link> 
                         </p>

                        </div>
                        <div className="my-2">
                        <span className="validationError ">{validationError.termsAndConditionsError}</span>
                        </div>

                        {/* <Form.Group className="field-set">
                            <Form.Check
                              type="checkbox"
                              name="referral_address"
                              onChange={inputHandler}
                              label="Referral Address"
                              className="pl-0"
                            /> 
                            <span className="validationError">{validationError.walletAddressError}</span>
                          </Form.Group> */}
                        {/* </div> */}

                        {/* <span className="validationError">{validationError.termsconditionError}</span> */}


                        <div className="field-set mb-2 mt-2 text-center ">
                          <Button
                            type="submit"
                            variant="main2"
                            className="custom-width text-white"
                            onClick={SubmitForm}
                            disabled={loadingSubmit} // Disable button when loading
                          >
                            {loadingSubmit ? (
                              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            ) : (
                              <>
                                <span className="icon"> </span> Sign Up
                              </>
                            )}
                          </Button>

                        </div>
                      </Form>
                    </div>
                  </Container>
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
        {showCongratulationModal && <CongratulationModal showCongratulationModal={setShowCongratulationModal} handleOpenLogin={handleOpenLogin} />}
      </section>
      {showModal && <SignUpEmailVerificationModal setshowModal={setshowModal} showModal={showModal} loading={loading} handleVerifyMobile={handleVerifyMobile} handleVerifyEmail={handleVerifyEmail}
        email={form.email} mobile={form.mobile} countryCode={countryCode} token={userToken} verificationStatus={verificationStatus} setIsTimerActive={setIsTimerActive} isTimerActive={isTimerActive} timer={timer} setTimer={setTimer}
      />}

      <Footer />
    </>
  );
};
export default Signup;
