import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import "../custom-tree.css";
import { MdVerified } from "react-icons/md";
import Dashboardheader from "../directives/dashboardheader";
import { ProfileUpdateAction, sendOtpForPhoneVerificationAction, sendOtpForVerificationAction, UserProfile } from "../Action/user.action";
import toast, { Toaster } from "react-hot-toast";
import EmailVerificationModal from "./Modals/EmailVerificationModal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";


const Profile = () => {
  const [userdata, setuserdata] = useState({});
  const [showModal, setshowModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);
  const [phoneVerificationLoading, setphoneVerificationLoading] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(true);
  const [image_file, setimage_file] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState(''); // Add state for mobile number
  const [countryCode, setCountryCode] = useState('in');

  useEffect(() => {
    getStepMintProfile();
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserdata((old) => ({ ...old, [name]: value }));
    if (name === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      setEmail(value);
    }
  };

  const handlePhoneChange = (value, data) => {
    setMobile(value.slice(data.dialCode.length));
    setCountryCode(data.dialCode);
  };

  const SubmitForm = async (e) => {
    e.preventDefault();

    setFirstNameError('');
    setLastNameError('');

    const trimmedFirstName = userdata.first_name?.trim();
    const trimmedLastName = userdata.last_name?.trim();

    // Validation for first name and last name
    let hasError = false;

    if (!trimmedFirstName) {
      setFirstNameError("First name is required.");
      hasError = true;
    }
    if (!trimmedLastName) {
      setLastNameError("Last name is required.");
      hasError = true;
    }


    if (hasError) {
      return;
    }

    let old_profile_pic = '';
    if (!image_file) {
      old_profile_pic = userdata?.profile_pic || '';
    }

    let userDatawithoutemail = {
      'first_name': userdata?.first_name || '',
      'last_name': userdata?.last_name || '',
      'bio': userdata?.bio || '',
      'profile_pic': image_file,
      'old_profile_pic': old_profile_pic,
      'whichquerytorun': "updateuser_without_email"
    };

    let userDatawithemail = {
      ...userDatawithoutemail,
      'email': userdata.email
    };

    const userData = email === userdata.email ? userDatawithoutemail : userDatawithemail;

    let res2 = await ProfileUpdateAction(userData);
    if (res2.success) {
      toast.success(res2.msg);
    } else {
      toast.error(res2.msg || "No changes made. Please modify before updating.");
    }
  }

  const getStepMintProfile = async () => {
    let res = await UserProfile();
    if (res.success) {
      setuserdata(res.data);
      setEmail(res.data.email);
    }
  }

  const handleVerifyEmail = (email) => {
    setVerificationStatus(email);
    setphoneVerificationLoading(false)
    if (email) {
      sendOtpForVerification(email);
    } else {
      toast.error("Please enter email");
    }
  };

  const handleVerifyPhone = (phone) => {
    setVerificationStatus(phone);
    setLoading(false)
    if (mobile) {
      sendOtpForVerification(phone);
    } else {
      toast.error("Please enter WhatsApp Number.");
    }
  };

  const sendOtpForVerification = async (emailss) => {
    if (!/^[\w-]+(\.[\w-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z]{2,})+$/.test(email)) {
      setEmailError(
        "Invalid email format. Please enter a valid email."
      );
      return;
    }
    if (emailss == 'email') {
      setEmailError('')
      setLoading(true);
    } else {
      setphoneVerificationLoading(true)
    }
    try {

      let res;
      if (emailss == "email") {
        let emaildata = {
          email: email
        }
        res = await sendOtpForVerificationAction(emaildata)
      } if (emailss == "mobile") {
        let data = {
          phone: mobile,
          countryCode
        }
        res = await sendOtpForPhoneVerificationAction(data)
      }

      if (res.success) {
        setIsTimerActive(true)
        setTimer(60)
        setshowModal(true);
        if (emailss === "mobile") {
          const jsonString = JSON.stringify({
            phone: res.data[0].phone,
            countryCode: res.data[0].countryCode,
            phoneVerified: res.data[0].phoneVerified,
            orderId: res.data[0].orderId
          });
          localStorage.setItem('userDetailsforPhoneVerification', jsonString)
        }
        setphoneVerificationLoading(false)
        setLoading(false)
        toast.success(res.msg);
      } else {
        toast.error(res.msg);
        setLoading(false);
        setphoneVerificationLoading(false)
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setphoneVerificationLoading(false)
      toast.error("An error occurred.");
    }
  };

  return (
    <>
      <Dashboardheader />
      <Toaster />
      <div className="shape_bg">
        <img src="assets/images/shape.svg" className="shape" />
        <img src="assets/images/shape1.svg" className="shape1" />
        <img src="assets/images/shape2.svg" className="shape2" />
        {/* <img src="assets/images/shape.svg" className="shape3" />
          <img src="assets/images/shape1.svg" className="shape4" /> */}
      </div>
      <div className="page-wrapper">
        <div className="position-relative overflow-hidden mt-5 pt-2 mb-5">

          <div className=" mb-5">
            <Container className="">
              <Row>
                <Col lg={2}></Col>
                <Col lg={8}>
                  <div className="card card-view rounded-20 p-4">
                    <div className="card-body p-3">
                      <Col lg={12} className="mb-3">
                        <div className="card-view rounded-20 p-4">
                          <Row>
                            <Col lg={12} className="mb-4">
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-4">
                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Label>First name</Form.Label>
                                      <Form.Control
                                        onChange={inputHandler}
                                        name='first_name'
                                        value={userdata?.first_name ? userdata?.first_name : ''}
                                        type="name"
                                        placeholder="First name" />
                                      {firstNameError && <div className="text-danger">{firstNameError}</div>}
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-4">
                                    <Form.Group controlId="formBasicEmail">
                                      <Form.Label>Last name</Form.Label>
                                      <Form.Control
                                        onChange={inputHandler}
                                        name='last_name'
                                        value={userdata?.last_name ? userdata?.last_name : ''}
                                        type="name"
                                        placeholder="Last name" />
                                      {lastNameError && <div className="text-danger">{lastNameError}</div>}
                                    </Form.Group>
                                  </div>
                                </Col>
                              </Row>
                              {userdata?.country_name &&
                                <div className="mb-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control
                                      onChange={inputHandler}
                                      style={{ paddingRight: '40px', background: "#00000057", color: "#747474" }} name='Country' value={userdata?.country_name ? userdata?.country_name : ''} type="name" placeholder="Country" disabled />
                                  </Form.Group>
                                </div>
                              }
                              <div className="mb-4">
                                <Form.Group controlId="formBasicEmail">
                                  <Form.Label>Email</Form.Label>
                                  {
                                    userdata?.is_email_verify ? <><Row>
                                      {/* <div className="d-flex flex-row align-items-center gap-2">  */}
                                      <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <Form.Control
                                          onChange={inputHandler}
                                          name='email'
                                          value={userdata?.email ? userdata?.email : ''}
                                          type="email"
                                          disabled={userdata?.is_email_verify}
                                          placeholder="Email"
                                          style={{ paddingRight: '40px', background: "#00000057", color: "#747474" }}
                                        />
                                        {userdata?.is_email_verify && (
                                          <MdVerified style={{
                                            position: 'absolute',
                                            right: '23px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            width: 25,
                                            height: 25,
                                            color: 'green',
                                            borderRadius: "50%",
                                            pointerEvents: 'none', // Makes sure the icon doesn't interfere with input
                                          }} />
                                        )}
                                      </div>
                                    </Row></> : <> <Row className="gy-3">
                                      <Col lg={9}>
                                        <Form.Control onChange={inputHandler} name='email' value={userdata?.email ? userdata?.email : ''} type="email" placeholder="Email" />
                                        {firstNameError && <div className="text-danger">{firstNameError}</div>}

                                      </Col>
                                      <Col lg={3} >

                                        <Button
                                          variant="main2"
                                          className="text-white w-100"
                                          style={{ height: 45 }}
                                          onClick={() => handleVerifyEmail('email')}
                                          disabled={loading} // Disable button when loading
                                        >
                                          {loading ? (
                                            <Spinner animation="border" size="sm" role="status">
                                              <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                          ) : (
                                            <Link
                                              className="text-light"
                                              style={{ textDecoration: "none", fontSize: 14 }}
                                            >
                                              Verify
                                            </Link>
                                          )}
                                        </Button>
                                      </Col>
                                    </Row>
                                    </>
                                  }
                                  {emailError && <div className="text-danger">{emailError}</div>}

                                </Form.Group>
                              </div>
                              {/* {userdata.mobile &&
                                <Row>
                                  <div className="mb-3" style={{ position: 'relative', display: 'inline-block' }}>
                                    <Form.Group controlId="formBasicEmail">
                                      <PhoneInput
                                        country={"in"}
                                        enableSearch={true}
                                        disabled={userdata?.is_phone_verify === false || userdata.is_phone_verify === undefined ? false : true}
                                        className="w-100 fc"
                                        style={{ backgroundColor: "transparent !important", borderColor: "#828282 !important" }}
                                        value={`${userdata.country_code || userdata.country_code}${userdata.mobile}`}
                                        name="mobile"
                                        onChange={inputHandler}
                                        placeholder="Enter mobile number"
                                      />
                                      {userdata?.is_phone_verify && (
                                        <MdVerified style={{
                                          position: 'absolute',
                                          right: '23px',
                                          top: '50%',
                                          transform: 'translateY(-50%)',
                                          width: 25,
                                          height: 25,
                                          color: 'green',
                                          borderRadius: "50%",
                                          pointerEvents: 'none',
                                        }} />
                                      )}
                                    </Form.Group>
                                  </div>
                                </Row>
                              } */}
                              <Row>
                                <div className="mb-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Whatsapp number</Form.Label>
                                    {
                                      userdata?.is_phone_verify ? <>
                                        <Row>
                                          <div className="mb-3" style={{ position: 'relative', display: 'inline-block' }}>
                                            <Form.Group controlId="formBasicEmail">
                                              <PhoneInput
                                                country={"in"}
                                                enableSearch={true}
                                                disabled={userdata?.is_phone_verify === false || userdata.is_phone_verify === undefined ? false : true}
                                                className="w-100 fc"
                                                style={{ backgroundColor: "transparent !important", borderColor: "#828282 !important" }}
                                                value={`${userdata.country_code || userdata.country_code}${userdata.mobile}`}
                                                name="mobile"
                                                onChange={inputHandler}
                                                placeholder="Enter mobile number"
                                              />
                                              {userdata?.is_phone_verify && (
                                                <MdVerified style={{
                                                  position: 'absolute',
                                                  right: '23px',
                                                  top: '50%',
                                                  transform: 'translateY(-50%)',
                                                  width: 25,
                                                  height: 25,
                                                  color: 'green',
                                                  borderRadius: "50%",
                                                  pointerEvents: 'none',
                                                }} />
                                              )}
                                            </Form.Group>
                                          </div>
                                        </Row>
                                      </> : <> <Row className="gy-3">
                                        <Col lg={9}>
                                          <PhoneInput
                                            country={"in"}
                                            enableSearch={true}
                                            className="w-100 fc"
                                            style={{ backgroundColor: "transparent !important", borderColor: "#828282 !important" }}
                                            value={`${91 || userdata.countryCode}${userdata.mobile}`}
                                            name="mobile"
                                            onChange={handlePhoneChange}
                                            placeholder="Enter mobile number"
                                          />

                                        </Col>
                                        <Col lg={3} >

                                          <Button
                                            variant="main2"
                                            className="text-white w-100"
                                            style={{ height: 45 }}
                                            onClick={() => handleVerifyPhone('mobile')}
                                            disabled={phoneVerificationLoading}
                                          >
                                            {phoneVerificationLoading ? (
                                              <Spinner animation="border" size="sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                              </Spinner>
                                            ) : (
                                              <Link
                                                className="text-light"
                                                style={{ textDecoration: "none", fontSize: 14 }}
                                              >
                                                Verify
                                              </Link>
                                            )}
                                          </Button>

                                        </Col>
                                      </Row>
                                      </>
                                    }
                                  </Form.Group>
                                </div>
                              </Row>

                              {userdata?.bnb_address &&
                                <div className="mb-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Wallet address</Form.Label>
                                    <Form.Control
                                      onChange={inputHandler}
                                      disabled
                                      name='bnb_address' value={userdata?.bnb_address ? userdata?.bnb_address : ''}
                                      type="name"
                                      placeholder="Wallet address" />
                                  </Form.Group>
                                </div>
                              }
                              {userdata.referredBy &&
                                <div className="mb-4">
                                  <Form.Group controlId="formBasicEmail">
                                    <Form.Label>Referred by</Form.Label>
                                    <Form.Control
                                      onChange={inputHandler}
                                      disabled name='refer_by'
                                      value={userdata?.referredBy ? userdata?.referredBy : ''}
                                      type="name"
                                      placeholder="Referral by " />
                                  </Form.Group>
                                </div>
                              }


                              <Button variant="main2" onClick={SubmitForm} className="cst-btn text-white px-4" style={{ textDecoration: "none", fontSize: 16 }}>
                                Update Profile
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col lg={2} sm={12}></Col>
              </Row>
            </Container>
            {showModal && <EmailVerificationModal setshowModal={setshowModal} showModal={showModal} email={userdata?.email} mobile={mobile} verificationStatus={verificationStatus} countryCode={countryCode} setIsTimerActive={setIsTimerActive} isTimerActive={isTimerActive} timer={timer} setTimer={setTimer} handleVerifyPhone={handleVerifyPhone} handleVerifyEmail={handleVerifyEmail} loading={loading} />}
          </div>
        </div>
      </div>
    </>
  );
};
export default Profile;
